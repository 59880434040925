import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CustomInput from '../../CommonComp/CustomInput/CustomInput';
import CustomButton from '../../CommonComp/Buttons/CustomButton';
import CustomBox from '../../CommonComp/CustomBox/CustomBox';
import { getAllCouponDetails } from '../../../Services/userApis';
import CustomAsyncSelect from '../../CommonComp/CustomInput/CustomAsyncSelect';
import MatTable from '../../Tables/MatTable';
import { getProductsBySearch } from '../../../Services/productApis';
import Divder from '../../CommonComp/Divder';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { userItemsSelector } from '../../../redux/user';
import { CreateUserOrder } from '../../../Services/orderApis';

const columnsForAddress = [
  'Select Address',
  'Name',
  'Mobile Number',
  'Pin Code',
  'City',
  'State',
  'Landmark',
  'Address Line 1',
  'Address Line 2',
];

function AddUserOrder({
  open,
  handleOpen,
  addressData,
  getListing,
  totalAddressData,
  selectedData,
  editMode,
  userId,
  setAddressData,
}) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { couponsList } = useSelector(userItemsSelector);
  const [imagesData, setImagesData] = useState([]);
  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    const tempValues = { ...values };
    const updatedProductPayload = tempValues?.products?.map((data) => {
      return {
        quantity: data?.productQuantity,
        size: data?.productSize,
        productId: data?.productData?.value,
      };
    });
    const payload = { ...tempValues, products: updatedProductPayload };
    let resp = await CreateUserOrder(payload);
    if (resp) {
      getListing();
      handleOpen(false);
    }
    setLoading(false);
  };

  const getListingCoupons = async (params = {}) => {
    dispatch(getAllCouponDetails());
  };
  useEffect(() => {
    getListingCoupons();
  }, []);

  const getProductListing = async (inputValue) => {
    if (inputValue && inputValue.length > 1) {
      const payload = {
        page: String(1),
        limit: 10,
        search: inputValue,
      };
      let data = await getProductsBySearch(payload);
      let updatedData = data?.data?.results?.map((item, index) => {
        return {
          value: item?._id,
          label: item?.sku,
          title: item?.title,
          url: item?.url,
          size: item?.size,
          imagesData: item?.imagesData[0]?.desktopImageURL,
          selectedSize: item?.size[0]?.name,
          selectedQuantity: 1,
        };
      });
      return updatedData || [];
    }
  };
  const formik = useFormik({
    initialValues: {
      userId: userId,
      addressId: addressData?._id,
      paymentMode: 'COD',
      products: [{ productData: '', productSize: '', productQuantity: 1 }],
      code: '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      userId: Yup.string().required('User Id is required'),
      addressId: Yup.string().required('Address Id is required'),
      paymentMode: Yup.string().required('Payment Mode required'),
      products: Yup.array().of(
        Yup.object().shape({
          // productData: Yup.object({
          //   userId: Yup.string().required('User Id is required'),
          // }),
          productData: Yup.object().required('product Data is required'),
          productSize: Yup.string().required('productSize is required'),
          productQuantity: Yup.string().required('productQuantity is required'),
        }),
      ),
      code: Yup.string(),
    }),
    onSubmit: handleConfirm,
  });
  const columnForPrivacyPolicyData = [
    {
      label: 'Product SKU',
      id: 'productData',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            width: '100%',
            minWidth: '200px',
          }}
        >
          <CustomAsyncSelect
            promiseOptions={getProductListing}
            // error={error}
            isMulti={false}
            // helperText={helperText}
            closeMenuOnSelect={false}
            controlStyle={{
              padding: 5,
            }}
            cacheOptions={true}
            // value={productsData}
            // handleChange={(e) => {
            //   setProductsData(e);
            // }}
            width={'100%'}
            dropDownZIndex={15000}
            labelShrink={true}
            label="Search"
            id={`products[${rowIndex}].productData`}
            name={`products[${rowIndex}].productData`}
            value={formik.values.products[rowIndex]?.productData}
            onChange={(e) =>
              formik.setFieldValue(`products[${rowIndex}].productData`, e)
            }
            error={
              formik.touched.products &&
              formik.touched.products[rowIndex]?.productData &&
              formik.errors.products &&
              formik.errors.products[rowIndex]?.productData
                ? true
                : false
            }
            helperText={
              formik.touched.products &&
              formik.touched.products[rowIndex]?.productData &&
              formik.errors.products &&
              formik.errors.products[rowIndex]?.productData
            }
          />
        </span>
      ),
    },
    {
      label: 'Product Image',
      id: 'productData',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          {' '}
          <img
            style={{ maxWidth: 100, maxHeight: 120, borderRadius: '5px' }}
            src={`${formik.values.products[rowIndex]?.productData?.imagesData}`}
          />
        </span>
      ),
    },
    {
      label: 'Product Size',
      id: 'productSize',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <CustomInput
            InputLabelProps={{ shrink: true }}
            select
            SelectProps={{
              native: true,
            }}
            variant="outlined"
            sx={{ minWidth: 300 }}
            label="Select Size"
            fullWidth={true}
            placeholder="Select Size"
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            id={`products[${rowIndex}].productSize`}
            name={`products[${rowIndex}].productSize`}
            value={columnData}
            onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            error={
              formik.touched.products &&
              formik.touched.products[rowIndex]?.productSize &&
              formik.errors.products &&
              formik.errors.products[rowIndex]?.productSize
                ? true
                : false
            }
            helperText={
              formik.touched.products &&
              formik.touched.products[rowIndex]?.productSize &&
              formik.errors.products &&
              formik.errors.products[rowIndex]?.productSize
            }
          >
            <option key="" value="">
              Select Size
            </option>
            {formik.values.products[rowIndex]?.productData?.size?.map(
              (data, index) => {
                return (
                  <option key={data?.name} value={data?.name}>
                    {data?.name}
                  </option>
                );
              },
            )}
          </CustomInput>
        </span>
      ),
    },
    {
      label: 'Product Quantity',
      id: 'productQuantity',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <CustomInput
            InputLabelProps={{ shrink: true }}
            select
            SelectProps={{
              native: true,
            }}
            fullWidth={true}
            variant="outlined"
            sx={{ minWidth: 300 }}
            label="Select Quantity"
            placeholder="Select Quantity"
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            id={`products[${rowIndex}].productQuantity`}
            name={`products[${rowIndex}].productQuantity`}
            value={columnData}
            onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            error={
              formik.touched.products &&
              formik.touched.products[rowIndex]?.productQuantity &&
              formik.errors.products &&
              formik.errors.products[rowIndex]?.productQuantity
                ? true
                : false
            }
            helperText={
              formik.touched.products &&
              formik.touched.products[rowIndex]?.productQuantity &&
              formik.errors.products &&
              formik.errors.products[rowIndex]?.productQuantity
            }
          >
            <option key="" value="">
              Select Quantity
            </option>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map((data, index) => {
              return (
                <option key={data} value={data}>
                  {data}
                </option>
              );
            })}
          </CustomInput>
        </span>
      ),
    },
  ];
  const actionBodyForPrivacyPolicyData = (data, rowIndex) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton
            onClick={() => handleRemoveNewPrivacyPolicyData(data, rowIndex)}
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              bgcolor: 'buttonDanger.main',
              color: 'buttonSecondary.contrastText',
            }}
          >
            Delete
          </CustomButton>
        </div>
      </>
    );
  };
  const handleAddNewPrivacyPolicyData = (value) => {
    let tempArray = [...formik.values?.products];
    let emptyObj = { productData: '', productSize: '', productQuantity: 1 };
    tempArray?.push(emptyObj);
    formik.setValues({ ...formik.values, products: tempArray });
  };
  const handleRemoveNewPrivacyPolicyData = (data, rowIndex) => {
    let tempArray = [...formik?.values?.products];
    tempArray.splice(rowIndex, 1);
    formik.setValues({ ...formik?.values, products: tempArray });
  };
  return (
    <div>
      <Dialog maxWidth={'xl'} fullWidth open={open}>
        <DialogTitle variant="h3">Create a New User Order</DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <Box>
                <CustomBox padding={3} sx={{ backgroundColor: '#b8dcfa26' }}>
                  <Typography variant="h3" sx={{ marginBottom: 2 }}>
                    Select User's Address
                  </Typography>
                  <Table aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        {columnsForAddress?.map((data, index) => {
                          return (
                            <TableCell
                              key={index}
                              align={index !== 0 ? 'center' : 'left'}
                            >
                              {data}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {totalAddressData?.map((row, index) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '1rem',
                            }}
                          >
                            <input
                              id={`address${index}${row.mobileNo}`}
                              className="radio-button-input"
                              type="radio"
                              name={`address${index}${row.mobileNo}`}
                              onChange={() => setAddressData(row)}
                              checked={
                                addressData?._id === row?._id ? true : false
                              }
                            />
                            <label
                              htmlFor={`address${index}${row.mobileNo}`}
                              style={{ cursor: 'pointer' }}
                            >
                              <Typography variant="h5" sx={{}}>
                                {addressData?._id === row?._id
                                  ? 'Selected'
                                  : 'Select'}
                              </Typography>
                            </label>
                          </TableCell>
                          <TableCell align="center">{row.name}</TableCell>
                          <TableCell align="center">{row.mobileNo}</TableCell>
                          <TableCell align="center">{row.pinCode}</TableCell>
                          <TableCell align="center">{row.city}</TableCell>
                          <TableCell align="center">{row.state}</TableCell>
                          <TableCell align="center">{row.landmark}</TableCell>
                          <TableCell align="center">
                            {row.addressLine1}
                          </TableCell>
                          <TableCell align="center">
                            {row.addressLine2}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomBox>
              </Box>
              <Divider sx={{ margin: '1rem 0' }} />
              <CustomBox padding={3} sx={{ backgroundColor: '#b8dcfa26' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h3" sx={{}}>
                    Add Product and Select Size, Quantity
                  </Typography>
                  <CustomButton
                    className="buttonDense"
                    onClick={() => handleAddNewPrivacyPolicyData(formik.values)}
                    color={'buttonPrimary'}
                  >
                    Add New Data
                  </CustomButton>
                </Box>
                <Divder spacing={1} />
                <MatTable
                  showCheckbox={false}
                  columns={columnForPrivacyPolicyData}
                  data={formik.values.products || []}
                  action={actionBodyForPrivacyPolicyData}
                  total={0}
                  pagination={false}
                />
              </CustomBox>
              <Divder spacing={1} />
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <CustomInput
                    InputLabelProps={{ shrink: true }}
                    select
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                    sx={{ minWidth: 300 }}
                    label="Select Size"
                    fullWidth={true}
                    placeholder="Select Size"
                    InputProps={{
                      classes: {
                        notchedOutline: 'rounded',
                      },
                    }}
                    id="paymentMode"
                    name="paymentMode"
                    value={formik.values.paymentMode}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.paymentMode &&
                      Boolean(formik.errors.paymentMode)
                    }
                    helperText={
                      formik.touched.paymentMode && formik.errors.paymentMode
                    }
                  >
                    <option key="COD" value="COD">
                      Cash On Delivery
                    </option>
                    <option key="Prepaid" value="Prepaid">
                      Prepaid
                    </option>
                  </CustomInput>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <CustomInput
                    InputLabelProps={{ shrink: true }}
                    select
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                    sx={{ minWidth: 300 }}
                    label="Select Coupon Code"
                    fullWidth={true}
                    placeholder="Select Coupon Code"
                    InputProps={{
                      classes: {
                        notchedOutline: 'rounded',
                      },
                    }}
                    id="code"
                    name="code"
                    value={formik.values.code}
                    onChange={formik.handleChange}
                    error={formik.touched.code && Boolean(formik.errors.code)}
                    helperText={formik.touched.code && formik.errors.code}
                  >
                    <option key="" value="">
                      Select Coupon Code
                    </option>
                    {couponsList?.items?.results?.map((data, index) => {
                      return (
                        <option key={index} value={data?.code}>
                          {data?.code}
                        </option>
                      );
                    })}
                  </CustomInput>
                </Grid>
              </Grid>
              <Divder spacing={1} />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginTop: 15,
                }}
              >
                <CustomButton
                  color={'buttonPrimary'}
                  type="submit"
                  // loading={loading}
                >
                  Confirm
                </CustomButton>
                <CustomButton
                  onClick={() => {
                    handleOpen(false);
                  }}
                  disabled={loading}
                >
                  Cancel
                </CustomButton>
              </div>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddUserOrder;
