import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { UserRegister } from '../../Services/LoginApi';
import { CreateUser, getAllUserListing } from '../../Services/userApis';
import { useDispatch } from 'react-redux';
import CustomPhoneInput from '../CommonComp/CustomInput/CustomPhoneInput';
import { formatDate } from '../../lib/helper';
import { AddNewStoneDetail, getAllStonesListing } from '../../Services/srmApis';

function CreateUserModal({ open, handleOpen }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    let resp = await AddNewStoneDetail({
      ...values,
      stock: values?.totalCarat,
    });
    if (resp) {
      resetForm({
        name: '',
        totalCarat: '',
      });
      dispatch(
        getAllStonesListing({
          page: String(1),
          limit: 10,
        }),
      );
      handleOpen(false);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      totalCarat: '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required('Rough Name is required.'),
      totalCarat: Yup.string().required('Total Carat is required.'),
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        fullWidth
        open={open}
        onClose={() => {
          handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          Add New Stone Details
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            // sx={{
            //   display: 'flex',
            //   flexDirection: 'column',
            //   m: 'auto',
            //   width: 'fit-content',
            // }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="name"
                  label="Stone Name"
                  placeholder="Stone Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="totalCarat"
                  label="Total Carat"
                  placeholder="Total Carat"
                  value={formik.values.totalCarat}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.totalCarat &&
                    Boolean(formik.errors.totalCarat)
                  }
                  helperText={
                    formik.touched.totalCarat && formik.errors.totalCarat
                  }
                />
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Add New Stone Details
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    email: '',
                    password: '',
                    fName: '',
                    lName: '',
                  });
                  handleOpen(false);
                }}
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CreateUserModal;
