import React, { useEffect, useState } from 'react';
import { useAuth } from '../Context/AuthProvider';
import AllRoutes from './Routes';
import { useNavigate } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
// import { appSettingApi } from '../Services/commonService';
import { useDispatch, useSelector } from 'react-redux';
import { setUserDetails } from '../redux/login';
import { commonItemsSelector } from '../redux/common';
import { getObjectFromStore } from '../storage/Storage';
import { setAllMenuListing } from '../redux/userMenus';

const AuthRoute = () => {
  const {user} = useAuth()
  const theme = useTheme()
  const dispatch = useDispatch()
  const [isMaintenance, setIsMaintenance] = useState(false)
  // const { appSetting } = useSelector(commonItemsSelector);
  const navigate = useNavigate()
  // useEffect(()=>{
  //   dispatch(appSettingApi())
  // },[])

  useEffect(()=>{
    dispatch(setUserDetails(user));
    const setUserMenusData = async() =>{
      if(user){
        let menuData = await getObjectFromStore('userMenu');
        if(menuData?.length){
          dispatch(setAllMenuListing(menuData));
        }
      }
    }
    setUserMenusData()
  },[user])

  // useEffect(() => {
  //   if(process.env.REACT_APP_ENV == 'prod'){
  //     if(appSetting?.items?.features?.website_status[0]?.key_value == "1"){
  //       setIsMaintenance(true)
  //     }else{
  //       setIsMaintenance(false)
  //       if(isMaintenance){
  //         navigate('/dashboard')
  //       }
  //     }
  //   } else{
  //     setIsMaintenance(false)
  //     if(isMaintenance){
  //       navigate('/dashboard')
  //     }
  //   }
  // }, [appSetting]);

  return <Box sx={{ height: '100%'}}>
    <Box sx={{
      backgroundColor: theme.backgroundColor.box.primary,
      height: '100%', 
      position: "fixed", 
      width: '100%',
      zIndex: -10  
     }}></Box>
    <AllRoutes user={user} isMaintenance={isMaintenance}/>
  </Box>
}

export default AuthRoute;  