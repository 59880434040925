import React, { useState } from 'react';
import MainLayout from '../Layouts/MainLayout';
import ProductDetails from './ProductDetails';

function ProductDetailsContainer({ isEdit }) {
  return (
    <MainLayout
      navTitle={'Product Details'}
      navSubTitle={'Welcome to Cotton Slice'}
    >
      <ProductDetails isEdit={isEdit} />
    </MainLayout>
  );
}

export default ProductDetailsContainer;
