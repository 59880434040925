import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import { userItemsSelector } from '../../redux/user';
import CustomPhoneInput from '../CommonComp/CustomInput/CustomPhoneInput';
import { DATA_LIMIT } from '../../data/constants';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import {
  getAllAWishlistListing,
  getAllAddressListing,
  getAllUserListing,
  getUserCartDetail,
  updateUser,
} from '../../Services/userApis';
import { formatDate } from '../../lib/helper';
import { toast } from 'react-toastify';
import UserAddressData from './StoneKapanData';
import Divder from '../CommonComp/Divder';
import AddEditAddress from '../Modals/User/AddEditAddress';
import WishlistData from './WishlistData';
import AddEditWishlist from '../Modals/User/AddEditWishlist';
import AddToCartData from './AddToCartData';
import AddEditCartList from '../Modals/User/AddEditCartList';
import AddUserOrder from '../Modals/User/AddUserOrder';
import OrderUserListing from './OrderUserListing';
import {
  getOrderDataList,
  getUserSpecificOrderList,
} from '../../Services/orderApis';
import { orderListingItemsSelector } from '../../redux/orderListing';
import Loader from '../CommonComp/CustomLoading/Loader';
import StoneKapanData from './StoneKapanData';
import AddEditKapanDetails from '../Modals/Stones/AddEditKapanDetails';
import { getSingleStonesListing } from '../../Services/srmApis';
import { srmItemSelector } from '../../redux/srm';

const limit = DATA_LIMIT;

function StoneDetailPage({ userId }) {
  const { addressList, singleUserList, wishlistData, cartList } =
    useSelector(userItemsSelector);
  const { singleStonesList } = useSelector(srmItemSelector);
  const { userSpecificOrderList } = useSelector(orderListingItemsSelector);
  const dispatch = useDispatch();
  const [openAddEditAddress, setOpenAddEditAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [openAddEditWishlist, setOpenAddEditWishlist] = useState(false);
  const [openCreateOrderModal, setOpenCreateOrderModal] = useState(false);
  const [openAddEditCartList, setOpenAddEditCartList] = useState(false);
  const handleConfirm = async (values, { resetForm }) => {
    if (values.mobile) {
      let payload = {
        firstName: values?.first_name,
        lastName: values?.last_name,
        email: values?.email,
        gender: values?.gender,
        dob: values?.date_of_birth,
      };
      let resp = await updateUser(payload, values?.user_id);
    }
  };
  const handleAdd = (value) => {
    setOpenAddEditAddress(value);
  };
  const handleAddWishlist = (value) => {
    setOpenAddEditWishlist(value);
  };
  const handleAddCartList = (value) => {
    setOpenAddEditCartList(value);
  };
  const handleOpenUserCreate = (value) => {
    setOpenCreateOrderModal(value);
  };
  console.log('singleStonesListsingleStonesList', singleStonesList);
  const formik = useFormik({
    initialValues: {
      createdAt: singleStonesList?.items?.createdAt || '',
      name: singleStonesList?.items?.name || '',
      totalCarat: singleStonesList?.items?.totalCarat || '',
    },
    enableReinitialize: true,
    onSubmit: handleConfirm,
  });
  const getUserDetails = async () => {
    dispatch(getSingleStonesListing(userId));
  };
  const handleChangeMobile = (val) => {
    formik.setFieldValue('mobile', val);
  };
  useEffect(() => {
    getUserDetails();
  }, []);
  const renderAddNewAddress = () => {
    return (
      <CustomButton
        className="buttonDense"
        onClick={() => handleAdd(true)}
        color={'buttonPrimary'}
      >
        Create New Stone Kapan
      </CustomButton>
    );
  };
  const renderAddNewWishlist = () => {
    return (
      <CustomButton
        className="buttonDense"
        onClick={() => handleAddWishlist(true)}
        color={'buttonPrimary'}
      >
        Add New Wishlist
      </CustomButton>
    );
  };
  const renderAddNewCart = () => {
    return (
      <CustomButton
        className="buttonDense"
        onClick={() => handleAddCartList(true)}
        color={'buttonPrimary'}
      >
        Add To Cart
      </CustomButton>
    );
  };
  const handleOpenCreateNewOrder = () => {
    if (addressList?.items?.results?.length === 0) {
      toast.error('Please Add Address First', { autoClose: 2500 });
      handleAdd(true);
    }
    if (addressList && addressList?.items?.results?.length > 0) {
      const defaultAdd = addressList?.items?.results?.find(
        (item) => item?.isDefault,
      );
      if (defaultAdd !== undefined) {
        setSelectedAddress(defaultAdd);
      } else {
        setSelectedAddress(addressList?.items?.results[0]);
      }
      handleOpenUserCreate(true);
    }
  };
  const handleMoveToCart = async (item, index, length) => {
    // let data = [item]
    // setInitialData(data)
    // handleAddToModalOpen(true, 0)
  };
  return (
    <div>
      {(addressList?.loading ||
        singleStonesList?.loading ||
        wishlistData?.loading ||
        cartList?.loading) && <Loader />}
      <form onSubmit={formik.handleSubmit}>
        <CustomBox padding={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomInput
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                disabled={true}
                name="createdAt"
                type="date"
                label="Stone Added Date"
                placeholder="Stone Added Date"
                value={formatDate(new Date(), 'yyyy-mm-dd')} //2017-06-13T13:00
                onChange={formik.handleChange}
                error={
                  formik.touched.createdAt && Boolean(formik.errors.createdAt)
                }
                helperText={formik.touched.createdAt && formik.errors.createdAt}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomInput
                name="name"
                label="Stone Name"
                fullWidth
                placeholder="Stone Name"
                value={formik.values.name}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomInput
                name="totalCarat"
                label="Total Carat"
                fullWidth
                placeholder="Total Carat"
                value={formik.values.totalCarat}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={(e) => {
                  formik.setFieldValue('totalCarat', e.target.value);
                }}
                error={
                  formik.touched.totalCarat && Boolean(formik.errors.totalCarat)
                }
                helperText={
                  formik.touched.totalCarat && formik.errors.totalCarat
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomInput
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                disabled={true}
                name="date_of_birth"
                type="date"
                label="Taiyaar Done Date"
                placeholder="Taiyaar Done Date"
                value={formatDate(new Date() + 5, 'yyyy-mm-dd')} //2017-06-13T13:00
                onChange={formik.handleChange}
                error={
                  formik.touched.date_of_birth &&
                  Boolean(formik.errors.date_of_birth)
                }
                helperText={
                  formik.touched.date_of_birth && formik.errors.date_of_birth
                }
              />
            </Grid>
          </Grid>
        </CustomBox>
      </form>
      <Divder spacing={1} />
      <CustomBox padding={0}>
        <StoneKapanData
          // wishListAllData={singleUserWishlistDetail?.items}
          addressListData={singleStonesList?.items?.kapan || []}
          // wishlistValue={singleUserWishlistDetail?.items?.wishlist?.rows || []}
          // userId={userId}
          // getWishlistListing={getWishlistListing}
          getListing={getUserDetails}
          stoneDetails={singleStonesList?.items}
          rightSection={renderAddNewAddress}
          handleMoveToCart={handleMoveToCart}
        />
      </CustomBox>
      {openAddEditAddress && (
        <AddEditKapanDetails
          open={openAddEditAddress}
          handleOpen={handleAdd}
          userData={singleStonesList?.items}
          getListing={getUserDetails}
        />
      )}
    </div>
  );
}

export default StoneDetailPage;
