import {
  DashBoardIcon,
  UserIcon,
  MasterIcon,
  PhotographerIcon,
  KeywordIcon,
  ImageIcon,
  OrderIcon,
  LogsIcon,
  CRMIcon,
  ReportsIcon,
  Settings,
  Send,
  CategoryIcon,
  CompanyIcon,
  StarIndustriesIcon,
  VideoCameraBack,
  People,
} from '../Asset/svg';
import { getItemFromStorage } from '../storage/Storage';

const IconArr = [
  DashBoardIcon,
  MasterIcon,
  UserIcon,
  PhotographerIcon,
  KeywordIcon,
  ImageIcon,
  VideoCameraBack,
  OrderIcon,
  OrderIcon,
  KeywordIcon,
  Settings,
  Send,
  People,
  LogsIcon,
  CRMIcon,
  CategoryIcon,
  CompanyIcon,
  StarIndustriesIcon,
  ReportsIcon,
  UserIcon,
];

export { IconArr };
let sideMenu = [
  {
    pathName: '/dashboard',
    label: 'Dashboard',
    icon: 0,
    value: 'dashboard',
  },
  {
    pathName: '/stones',
    label: 'All Stones',
    icon: 4,
    value: 'stones',
  },
  {
    pathName: '/kapan',
    label: 'All Kapan Details',
    icon: 6,
    value: 'kapan',
  },
  {
    pathName: '/galexyPlanning',
    label: 'Galexy Planning',
    icon: 10,
    value: 'galexyPlanning',
  },
  {
    pathName: '/employees',
    label: 'Employees',
    icon: 2,
    value: 'employees',
  },
  // {
  //   pathName: '/departments',
  //   label: 'Departments',
  //   icon: 13,
  //   value: 'departments',
  // },
];

export { sideMenu };

export const MenuList = [
  'Dashboard',
  'Master',
  'Users',
  'Contributors',
  'Keywords',
  'Images',
  'Videos',
  'Orders',
  'IRN',
  'Subscriptions',
  'Settings',
  'Contact Us',
  'Admin Users & Access',
  'Logs',
  'CRM',
  'Categories',
  'Companies',
  'Star Industries',
  'Reports',
];

export const NewMenuList = [
  { label: 'Dashboard', value: 'dashboard' },
  { label: 'Master', value: 'master' },
  { label: 'Users', value: 'users' },
  { label: 'Contributors', value: 'contributors' },
  { label: 'Keywords', value: 'keywords' },
  { label: 'Images', value: 'images' },
  { label: 'Videos', value: 'videos' },
  { label: 'Orders', value: 'orders' },
  { label: 'Subscriptions', value: 'subscriptions' },
  { label: 'IRN', value: 'irn' },
  { label: 'Settings', value: 'settings' },
  { label: 'Contact Us', value: 'contact_us' },
  { label: 'Admin Users & Access', value: 'admin_users' },
  { label: 'Logs', value: 'logs' },
  { label: 'CRM', value: 'crm' },
  { label: 'Categories', value: 'categories' },
  { label: 'Companies', value: 'companies' },
  { label: 'Star Industries', value: 'star_indutries' },
  { label: 'Reports', value: 'report' },
];
