import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import LoginLayout from '../Screen/Layouts/LoginLayout';
import { ProtectedRoute } from './ProtectedRoute';
import DashboardContainer from '../Screen/Dashboard/index';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageNotFound from '../Screen/NotFound/PageNotFound';
import UserContainer from '../Screen/User';
import ProductContainer from '../Screen/Product';
import UserDetailsContainer from '../Screen/User/UserDetailsContainer';
import { MaintenanceRoute } from './MaintenanceRoute';
import MaintenanceLayout from '../Screen/Layouts/MaintenanceLayout';
import { isAccessiblePath } from '../lib/helper';
import CategoryContainer from '../Screen/Category';
import CmsContainer from '../Screen/Cms';
import AddProductContainer from '../Screen/User/AddProductContainer';
import ProductDetailsContainer from '../Screen/Product/ProductDetailsContainer';
import CouponContainer from '../Screen/Coupon';
import CouponDetailsContainer from '../Screen/Coupon/CouponDetailsContainer';
import HomepageDetailsContainer from '../Screen/Cms/Homepage/HomepageDetailsContainer';
import PolicyContainer from '../Screen/Policy';
import SearchPageDetailContainer from '../Screen/Cms/SearchPage/SearchPageDetailContainer';
import ReviewsContainer from '../Screen/Reviews';
import ReviewDetailsContainer from '../Screen/Reviews/ReviewDetailsContainer';
import OrdersContainer from '../Screen/Orders';
import OrderDetailsContainer from '../Screen/Orders/OrderDetailsContainer';
import ManageOrdersContainer from '../Screen/ManageOrders';
import ShippingManageContainer from '../Screen/ShippingManage';
import PickupDetailContainer from '../Screen/ShippingManage/PickupDetailContainer';
import StoneContainer from '../Screen/Stones';
import StoneDetailsContainer from '../Screen/Stones/StoneDetailsContainer';
import KapanContainer from '../Screen/Kapan';
import KapanDetailsContainer from '../Screen/Kapan/KapanDetailsContainer';
import DepartmentContainer from '../Screen/Department';
import GalexyPlanningContainer from '../Screen/GalexyPlanning';
const AllRoutes = ({ user, isMaintenance }) => {
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route element={<MaintenanceRoute isMaintenance={isMaintenance} />}>
          {/* ============= LOGEDOUT ROUTES ========== */}
          <Route index element={<LoginLayout />} />
          <Route path="login" element={<LoginLayout />} />

          {/* ============= LOGEDIN ROUTES ========== */}

          <Route element={<ProtectedRoute isAllowed={!!user} />}>
            <Route path="dashboard" element={<DashboardContainer />} />
            <Route path="employees">
              <Route index={true} element={<UserContainer />} />
              <Route path="userDetail">
                <Route
                  index={true}
                  element={<Navigate to={'/employees'} replace={true} />}
                />
                <Route path=":userId" element={<UserDetailsContainer />} />
              </Route>
            </Route>
            <Route path="stones">
              <Route index={true} element={<StoneContainer />} />
              <Route path="stoneDetails">
                <Route
                  index={true}
                  element={<Navigate to={'/stones'} replace={true} />}
                />
                <Route path=":stoneId" element={<StoneDetailsContainer />} />
              </Route>
            </Route>
            <Route path="kapan">
              <Route index={true} element={<KapanContainer />} />
              <Route path="kapanDetails">
                <Route
                  index={true}
                  element={<Navigate to={'/kapan'} replace={true} />}
                />
                <Route path=":stoneId" element={<KapanDetailsContainer />} />
              </Route>
            </Route>
            <Route path="departments">
              <Route index={true} element={<DepartmentContainer />} />
              <Route path="departmentDetails">
                <Route
                  index={true}
                  element={<Navigate to={'/departments'} replace={true} />}
                />
                <Route
                  path=":departmentId"
                  element={<UserDetailsContainer />}
                />
              </Route>
            </Route>
            <Route path="galexyPlanning">
              <Route index={true} element={<GalexyPlanningContainer />} />
              <Route path="departmentDetails">
                <Route
                  index={true}
                  element={<Navigate to={'/galexyPlanning'} replace={true} />}
                />
                <Route
                  path=":departmentId"
                  element={<UserDetailsContainer />}
                />
              </Route>
            </Route>
            {/* <Route path="product">
              <Route index={true} element={<ProductContainer />} />
              <Route path="productDetail">
                <Route
                  index={true}
                  element={<Navigate to={'/product'} replace={true} />}
                />
                <Route
                  path=":productId"
                  element={<ProductDetailsContainer isEdit={true} />}
                />
              </Route>
              <Route
                path="addProduct"
                element={<ProductDetailsContainer isEdit={false} />}
              />
            </Route>
            <Route path="coupon">
              <Route index={true} element={<CouponContainer />} />
              <Route path="couponDetail">
                <Route
                  index={true}
                  element={<Navigate to={'/coupon'} replace={true} />}
                />
                <Route
                  path=":couponId"
                  element={<CouponDetailsContainer isEdit={true} />}
                />
              </Route>
              <Route
                path="addCoupon"
                element={<CouponDetailsContainer isEdit={false} />}
              />
            </Route>
            <Route path="category">
              <Route index={true} element={<CategoryContainer />} />
            </Route>
            <Route path="cms">
              <Route index={true} element={<CmsContainer />} />
              <Route path="homepage">
                <Route path="homepageDetail">
                  <Route
                    index={true}
                    element={<Navigate to={'/cms'} replace={true} />}
                  />
                  <Route
                    path=":cmsId"
                    element={<HomepageDetailsContainer isEdit={true} />}
                  />
                </Route>
                <Route
                  index={true}
                  element={<Navigate to={'/cms'} replace={true} />}
                />
                <Route
                  path="addHomePage"
                  element={<HomepageDetailsContainer isEdit={false} />}
                />
              </Route>
              <Route path="searchPage">
                <Route path="searchPageDetail">
                  <Route
                    index={true}
                    element={<Navigate to={'/cms'} replace={true} />}
                  />
                  <Route
                    path=":cmsId"
                    element={<SearchPageDetailContainer isEdit={true} />}
                  />
                </Route>
                <Route
                  index={true}
                  element={<Navigate to={'/cms'} replace={true} />}
                />
                <Route
                  path="addsearchPage"
                  element={<SearchPageDetailContainer isEdit={false} />}
                />
              </Route>
            </Route>
            <Route path="policy">
              <Route index={true} element={<PolicyContainer />} />
            </Route>
            <Route path="reviews">
              <Route index={true} element={<ReviewsContainer />} />
              <Route path="reviewDetail">
                <Route
                  index={true}
                  element={<Navigate to={'/reviews'} replace={true} />}
                />
                <Route
                  path=":reviewId"
                  element={<ReviewDetailsContainer isEdit={true} />}
                />
              </Route>
              <Route
                path="addReview"
                element={<ReviewDetailsContainer isEdit={false} />}
              />
            </Route>
            <Route path="orders">
              <Route index={true} element={<OrdersContainer />} />
              <Route path="orderDetails">
                <Route
                  index={true}
                  element={<Navigate to={'/orders'} replace={true} />}
                />
                <Route
                  path=":orderId"
                  element={<OrderDetailsContainer isEdit={true} />}
                />
              </Route>
              <Route
                path="createOrder"
                element={<OrderDetailsContainer isEdit={false} />}
              />
            </Route>
            <Route path="manageOrders">
              <Route index={true} element={<ManageOrdersContainer />} />
            </Route>
            <Route path="shiprocket">
              <Route index={true} element={<ShippingManageContainer />} />
              <Route path="pickup">
                <Route
                  index={true}
                  element={<Navigate to={'/shiprocket'} replace={true} />}
                />
                <Route
                  path=":pickupId"
                  element={<PickupDetailContainer isEdit={true} />}
                />
              </Route>
            </Route> */}
          </Route>

          {/* <Route
            path="analytics"
            element={
              <ProtectedRoute
                redirectPath="/home"
                isAllowed={
                  !!user && user?.permissions?.includes('analyze')
                }
              >
                <Analytics />
              </ProtectedRoute>
            }
          />
          <Route
            path="admin"
            element={
              <ProtectedRoute
                redirectPath="/home"
                isAllowed={!!user && user?.roles?.includes('admin')}
              >
                <Admin />
              </ProtectedRoute>
            }
          /> */}

          {/* ============= COMMON ROUTES ========== */}
        </Route>
        <Route path="*" element={<PageNotFound />} />
        <Route path="maintenance" element={<MaintenanceLayout />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
