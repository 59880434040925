import React, { useState } from 'react';
import MainLayout from '../Layouts/MainLayout';
import StoneDetails from './StoneDetails';

function StoneDetailsContainer() {
  return (
    <MainLayout navTitle={'Stone Details'} navSubTitle={'Welcome to SRM'}>
      <StoneDetails />
    </MainLayout>
  );
}

export default StoneDetailsContainer;
