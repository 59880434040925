import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import CustomInput from '../../CommonComp/CustomInput/CustomInput';
import CustomButton from '../../CommonComp/Buttons/CustomButton';
import CustomCheckbox from '../../CommonComp/CustomInput/CheckboxField';
import { formatDate } from '../../../lib/helper';
import CustomPhoneInput from '../../CommonComp/CustomInput/CustomPhoneInput';
import {
  CreateAddress,
  addRemoveWishlist,
  updateAddress,
} from '../../../Services/userApis';
import CustomAsyncSelect from '../../CommonComp/CustomInput/CustomAsyncSelect';
import CustomIconButtons from '../../CommonComp/Buttons/IconButtons';
import { WishlistIcon } from '../../../Asset/svg';
import {
  getAllProductListing,
  getProductsBySearch,
} from '../../../Services/productApis';

function AddEditWishlist({
  open,
  handleOpen,
  userData,
  getListing,
  selectedData,
  editMode,
  userId,
}) {
  const [loading, setLoading] = useState(false);
  const [imagesData, setImagesData] = useState([]);

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    let resp = editMode
      ? await updateAddress(values, selectedData?._id)
      : await CreateAddress(values);
    if (resp) {
      resetForm({
        userId: '',
        email: '',
        name: '',
        mobileNo: '',
        type: '',
        pinCode: '',
        city: '',
        state: '',
        addressLine1: '',
        addressLine2: '',
        landmark: '',
        isDefault: false,
      });
      getListing();
      handleOpen(false);
    }
    setLoading(false);
  };
  const handleChangeMobile = (val) => {
    formik.setFieldValue('mobileNo', val);
  };
  const handleDelete = (index) => {
    let updatedData = [...imagesData];
    updatedData.splice(index, 1);
    setImagesData(updatedData);
  };
  const getProductListing = async (inputValue) => {
    if (inputValue && inputValue.length > 1) {
      const payload = {
        page: String(1),
        limit: 10,
        search: inputValue,
      };
      let data = await getProductsBySearch(payload);
      let updatedData = data?.data?.results?.map((item, index) => {
        return {
          value: item?._id,
          label: item?.sku,
          title: item?.title,
          url: item?.url,
          imagesData: item?.imagesData[0]?.desktopImageURL,
        };
      });
      return updatedData || [];
    }
  };
  const formik = useFormik({
    initialValues: {
      userId: editMode ? selectedData?.user : userData ? userData?._id : '',
      email: editMode ? selectedData?.email : userData ? userData?.email : '',
      name: editMode
        ? selectedData?.name
        : userData
        ? userData?.firstName + ' ' + userData?.lastName
        : '',
      mobileNo: editMode
        ? selectedData?.mobileNo
        : userData
        ? userData?.mobileNo
        : '',
      type: editMode ? selectedData?.type : '',
      pinCode: editMode ? selectedData?.pinCode : '',
      city: editMode ? selectedData?.city : '',
      state: editMode ? selectedData?.state : '',
      addressLine1: editMode ? selectedData?.addressLine1 : '',
      addressLine2: editMode ? selectedData?.addressLine2 : '',
      landmark: editMode ? selectedData?.landmark : '',
      isDefault: selectedData?.isDefault || false,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Email is Invalid')
        .required('Please enter Email'),
      name: Yup.string().required('Name is required'),
      mobileNo: Yup.string()
        .max(18, 'Mobile Number is Invalid')
        .min(10, 'Mobile Number is Invalid')
        .required('Mobile Number is required'),
      type: Yup.string().required('Type is required'),
      pinCode: Yup.number('Pin Code is Invalid').required(
        'Pincode is required',
      ),
      city: Yup.string().required('City is required'),
      state: Yup.string().required('State is required'),
      addressLine1: Yup.string().required('Address Line 1 is required'),
      addressLine2: Yup.string('Address Line 2 is Invalid'),
      landmark: Yup.string().required('Landmark is required'),
      isDefault: Yup.boolean('Is Default is Invalid').required(
        'Is Default is required',
      ),
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        fullWidth
        open={open}
        onClose={() => {
          handleOpen(false);
        }}
      >
        <DialogTitle variant="h3">
          {editMode ? 'Edit Wishlist' : 'Add Wishlist'}
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            // sx={{
            //   width: 400
            // }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <CustomAsyncSelect
                  promiseOptions={getProductListing}
                  // error={error}
                  // helperText={helperText}
                  closeMenuOnSelect={false}
                  controlStyle={{
                    padding: 5,
                  }}
                  cacheOptions={true}
                  value={imagesData}
                  handleChange={(e) => {
                    setImagesData(e);
                  }}
                  width={'100%'}
                  dropDownZIndex={15000}
                  labelShrink={true}
                  label="Search"
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  className="border-1 rounded-3"
                  mt={5}
                  px={2}
                  py={1}
                  minHeight={150}
                >
                  <Typography variant="h6">Preview</Typography>
                  {/* {imagesData?.length ?  */}
                  {imagesData?.map((data, index) => {
                    return (
                      <Box
                        sx={{
                          display: 'flex',
                          overflow: 'hidden',
                          overflowX: 'auto',
                          padding: '1rem',
                          gap: '2rem',
                          alignItems: 'center',
                        }}
                        key={index}
                      >
                        <img
                          src={data?.imagesData}
                          alt="Image"
                          width={150}
                          height={'auto'}
                          style={{ borderRadius: '1rem' }}
                        />
                        <Typography
                          variant="span"
                          style={{ whiteSpace: 'nowrap' }}
                          // className={'link-text'}
                          // onClick={() => {
                          //   customHistory.push(`order/orderDetails/${columnData}`);
                          // }}
                        >
                          {data?.label}
                        </Typography>
                        {/* <CustomButton
                          sx={{
                            bgcolor: 'buttonSecondary.second',
                            color: 'buttonSecondary.contrastText',
                            height: 'fit-content',
                            padding: '1rem !important',
                          }}
                          color={'buttonPrimary'}
                          className="buttonDense noMinWidth"
                          loading={loading}
                          onClick={async () => {
                            let resp = await addRemoveWishlist('');
                            if (resp) {
                              dispatch(
                                getAllAWishlistListing({
                                  page: 1,
                                  limit: 5,
                                }),
                              );
                            }
                          }}
                        >
                          {' '}
                          <WishlistIcon
                            style={{
                              fill: 'red',
                              width: '2rem',
                              height: '2rem',
                            }}
                          />
                        </CustomButton> */}
                        <CustomButton
                          onClick={async () => {
                            await addRemoveWishlist(data?.value, {
                              userId: userId,
                            });
                            handleDelete(index);
                          }}
                          sx={{
                            padding: '0.5rem 2rem',
                            marginRight: 1,
                            fontSize: '14px',
                            whiteSpace: 'nowrap',
                            bgcolor: 'buttonSecondary.main',
                            color: 'buttonSecondary.contrastText',
                          }}
                        >
                          Add To Wishlist
                        </CustomButton>
                      </Box>
                    );
                  })}
                  {/* :  */}

                  <Typography
                    variant="p"
                    component={'p'}
                    className="fontWeight500"
                    textAlign={'center'}
                    pt={4}
                  >
                    No Data Available
                  </Typography>
                  {/* } */}
                </Box>
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Confirm
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    email: '',
                    status: '',
                    nextFollowUpsDate: '',
                    heading: '',
                    description: '',
                    createdBy: '',
                  });
                  handleOpen(false);
                }}
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddEditWishlist;
