import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  allCategoryList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  subCategoryList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
};

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAllCategoryListing: (state, action) => {
      state.allCategoryList.items = action.payload;
      state.allCategoryList.error = {
        isError: false,
        message: '',
      };
    },
    setAllCategoryListingLoading: (state, action) => {
      state.allCategoryList.loading = action.payload;
    },
    setAllCategoryListingError: (state, action) => {
      state.allCategoryList.items = {};
      state.allCategoryList.error = action.payload;
    },
    setSubCategoryListing: (state, action) => {
      state.subCategoryList.items = action.payload;
      state.subCategoryList.error = {
        isError: false,
        message: '',
      };
    },
    setSubCategoryListingLoading: (state, action) => {
      state.subCategoryList.loading = action.payload;
    },
    setSubCategoryListingError: (state, action) => {
      state.subCategoryList.items = {};
      state.subCategoryList.error = action.payload;
    },

    clearSingleUserDetails: (state, action) => {
      state.allCategoryList.items = {};
      state.subCategoryList.items = {};
    },
  },
});

export default categorySlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setAllCategoryListing,
  setAllCategoryListingLoading,
  setAllCategoryListingError,
  setSubCategoryListing,
  setSubCategoryListingLoading,
  setSubCategoryListingError,
} = categorySlice.actions;

export const categoryItemsSelector = (state) => state.category;
