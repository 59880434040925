import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { errorMessage } from '../lib/helper';
import { STATUS_200, STATUS_300 } from '../data/constants';
import {
  setAllOrdersListing,
  setAllOrdersListingError,
  setSingleOrderListing,
  setSingleOrderListingError,
  setAllOrdersListingLoading,
  setSingleOrderListingLoading,
  setUserSpecificOrderListLoading,
  setUserSpecificOrderList,
  setUserSpecificOrderListError,
  setPickupListLoading,
  setPickupList,
  setPickupListError,
} from '../redux/orderListing';

export const getOrderDataList = (payload, isSingleUser) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(
          isSingleUser
            ? setSingleOrderListingLoading(true)
            : setAllOrdersListingLoading(true),
        );
        const resp = await axios.get(`${ApiConfig.getAllOrderListing}`, {
          params: payload,
        });
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(
            isSingleUser
              ? setSingleOrderListing(resp.data.data)
              : setAllOrdersListing(resp.data.data),
          );
          dispatch(
            isSingleUser
              ? setSingleOrderListingLoading(false)
              : setAllOrdersListingLoading(false),
          );
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          isSingleUser
            ? setSingleOrderListingError({
                isError: true,
                message: errorMessage(e),
              })
            : setAllOrdersListingError({
                isError: true,
                message: errorMessage(e),
              }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(
          isSingleUser
            ? setSingleOrderListingLoading(false)
            : setAllOrdersListingLoading(false),
        );
      }
    };
  }
};

export const getPickupListOfOrders = (payload, isSingleUser) => {
  // if (payload !== undefined) {
  return async (dispatch) => {
    try {
      dispatch(setPickupListLoading(true));
      const resp = await axios.get(`${ApiConfig.getAllPickupListing}`);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        dispatch(setPickupList(resp.data.data));
        dispatch(setPickupListLoading(false));
      } else {
        throw resp;
      }
    } catch (e) {
      dispatch(
        setPickupListError({
          isError: true,
          message: errorMessage(e),
        }),
      );
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      dispatch(setPickupListLoading(false));
    }
  };
  // };
};

export const getUserSpecificOrderList = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setUserSpecificOrderListLoading(true));
        const resp = await axios.get(`${ApiConfig.getAllOrderListing}`, {
          params: payload,
        });
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setUserSpecificOrderList(resp.data.data));
          dispatch(setUserSpecificOrderListLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setUserSpecificOrderListError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setUserSpecificOrderListLoading(false));
      }
    };
  }
};

export const CreateUserOrder = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(
        `${ApiConfig.adminUserOrderCreate}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const CreateNonExistingUserOrder = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(
        `${ApiConfig.adminNonUserOrderCreate}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const UpdateOrderDetails = async (payload, orderId) => {
  if (payload !== undefined && orderId !== undefined) {
    try {
      const resp = await axios.put(
        `${ApiConfig.adminUserOrderUpdate}/${orderId}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const UpdateOrderStatus = async (payload, orderId) => {
  if (payload !== undefined && orderId !== undefined) {
    try {
      const resp = await axios.put(
        `${ApiConfig.updateOrderStatus}/${orderId}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const generateShipmentAWBOrder = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.put(`${ApiConfig.generateAwbOrders}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const cancelShiprocketOrder = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.put(
        `${ApiConfig.cancelShiprocketOrder}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const cancelShiprocketShipment = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.put(
        `${ApiConfig.cancelShiprocketShipment}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const generateShiprocketPickup = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.put(
        `${ApiConfig.generateShiprocketPickup}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};
