import React, { useState } from 'react';
import MainLayout from '../Layouts/MainLayout';
import KapanDetails from './KapanDetails';

function KapanDetailsContainer() {
  return (
    <MainLayout navTitle={'Kapan Details'} navSubTitle={'Welcome to SRM'}>
      <KapanDetails />
    </MainLayout>
  );
}

export default KapanDetailsContainer;
