import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import { userItemsSelector } from '../../redux/user';
import CustomPhoneInput from '../CommonComp/CustomInput/CustomPhoneInput';
import { DATA_LIMIT } from '../../data/constants';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import {
  getAllAWishlistListing,
  getAllAddressListing,
  getAllUserListing,
  getUserCartDetail,
  updateUser,
} from '../../Services/userApis';
import { formatDate } from '../../lib/helper';
import { toast } from 'react-toastify';
import UserAddressData from './UserAddressData';
import Divder from '../CommonComp/Divder';
import AddEditAddress from '../Modals/User/AddEditAddress';
import WishlistData from './WishlistData';
import AddEditWishlist from '../Modals/User/AddEditWishlist';
import AddToCartData from './AddToCartData';
import AddEditCartList from '../Modals/User/AddEditCartList';
import AddUserOrder from '../Modals/User/AddUserOrder';
import OrderUserListing from './OrderUserListing';
import {
  getOrderDataList,
  getUserSpecificOrderList,
} from '../../Services/orderApis';
import { orderListingItemsSelector } from '../../redux/orderListing';
import Loader from '../CommonComp/CustomLoading/Loader';

const limit = DATA_LIMIT;

function UserDetailPage({ userId }) {
  const { addressList, singleUserList, wishlistData, cartList } =
    useSelector(userItemsSelector);
  const { userSpecificOrderList } = useSelector(orderListingItemsSelector);
  const dispatch = useDispatch();
  const [openAddEditAddress, setOpenAddEditAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [openAddEditWishlist, setOpenAddEditWishlist] = useState(false);
  const [openCreateOrderModal, setOpenCreateOrderModal] = useState(false);
  const [openAddEditCartList, setOpenAddEditCartList] = useState(false);
  const handleConfirm = async (values, { resetForm }) => {
    if (values.mobile) {
      let payload = {
        firstName: values?.first_name,
        lastName: values?.last_name,
        email: values?.email,
        gender: values?.gender,
        dob: values?.date_of_birth,
      };
      let resp = await updateUser(payload, values?.user_id);
    }
  };
  const handleAdd = (value) => {
    setOpenAddEditAddress(value);
  };
  const handleAddWishlist = (value) => {
    setOpenAddEditWishlist(value);
  };
  const handleAddCartList = (value) => {
    setOpenAddEditCartList(value);
  };
  const handleOpenUserCreate = (value) => {
    setOpenCreateOrderModal(value);
  };
  const formik = useFormik({
    initialValues: {
      email: singleUserList?.items?.email?.trim() || '',
      user_id: singleUserList?.items?._id || '',
      first_name: singleUserList?.items?.firstName || '',
      last_name: singleUserList?.items?.lastName || '',
      mobile: '91' + singleUserList?.items?.mobileNo || '',
      gender: singleUserList?.items?.gender || '',
      date_of_birth: singleUserList?.items?.dob || '',
      joined_date: singleUserList?.items?.createdAt || '',
    },
    enableReinitialize: true,
    onSubmit: handleConfirm,
  });
  const getUserDetails = async () => {
    const payload = {
      page: String(1),
      limit: limit,
      user_id: userId,
    };
    dispatch(getAllUserListing(payload, true));
  };
  const getAddressDetails = async () => {
    const payloadForAddress = {
      page: String(1),
      limit: limit,
      userId: userId,
    };
    dispatch(getAllAddressListing(payloadForAddress));
  };
  const getWishlistDetails = async () => {
    const payloadForAddress = {
      page: String(1),
      limit: limit,
      userId: userId,
    };
    dispatch(getAllAWishlistListing(payloadForAddress));
  };
  const getCartDetails = async () => {
    const payloadForAddress = {
      userId: userId,
    };
    dispatch(getUserCartDetail(payloadForAddress));
  };
  const getUserOrderDetails = async () => {
    const payloadForUserOrder = {
      userId: userId,
    };
    dispatch(getUserSpecificOrderList(payloadForUserOrder));
  };
  const handleChangeMobile = (val) => {
    formik.setFieldValue('mobile', val);
  };
  useEffect(() => {
    // getUserDetails();
    // getAddressDetails();
    // getWishlistDetails();
    // getCartDetails();
    // getUserOrderDetails();
  }, []);
  const renderAddNewAddress = () => {
    return (
      <CustomButton
        className="buttonDense"
        onClick={() => handleAdd(true)}
        color={'buttonPrimary'}
      >
        Add New Address
      </CustomButton>
    );
  };
  const renderAddNewWishlist = () => {
    return (
      <CustomButton
        className="buttonDense"
        onClick={() => handleAddWishlist(true)}
        color={'buttonPrimary'}
      >
        Add New Wishlist
      </CustomButton>
    );
  };
  const renderAddNewCart = () => {
    return (
      <CustomButton
        className="buttonDense"
        onClick={() => handleAddCartList(true)}
        color={'buttonPrimary'}
      >
        Add To Cart
      </CustomButton>
    );
  };
  const handleOpenCreateNewOrder = () => {
    if (addressList?.items?.results?.length === 0) {
      toast.error('Please Add Address First', { autoClose: 2500 });
      handleAdd(true);
    }
    if (addressList && addressList?.items?.results?.length > 0) {
      const defaultAdd = addressList?.items?.results?.find(
        (item) => item?.isDefault,
      );
      if (defaultAdd !== undefined) {
        setSelectedAddress(defaultAdd);
      } else {
        setSelectedAddress(addressList?.items?.results[0]);
      }
      handleOpenUserCreate(true);
    }
  };
  const handleMoveToCart = async (item, index, length) => {
    // let data = [item]
    // setInitialData(data)
    // handleAddToModalOpen(true, 0)
  };
  return (
    <div>
      {(addressList?.loading ||
        singleUserList?.loading ||
        wishlistData?.loading ||
        cartList?.loading) && <Loader />}
      <CustomBox sx={{ marginBottom: '1rem', textAlign: 'right' }}>
        <CustomButton
          sx={{ padding: '12px 15px' }}
          color={'buttonPrimary'}
          onClick={() => handleOpenCreateNewOrder()}
        >
          Create New Order
        </CustomButton>
      </CustomBox>
      <form onSubmit={formik.handleSubmit}>
        <CustomBox padding={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomPhoneInput
                label="Mobile"
                id="mobile"
                name="mobile"
                className="main-login-form"
                fullWidth
                disabled
                placeholder="Mobile Number"
                native={true}
                value={formik.values.mobile}
                defaultCountry={`in`}
                country={`in`}
                divStyle={{
                  paddingTop: 4,
                }}
                variant="outlined"
                onChange={(newValue, info) => {
                  handleChangeMobile(newValue);
                }}
                onlyCountries={['in']}
                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                helperText={formik.touched.mobile && formik.errors.mobile}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="email"
                label="Email"
                placeholder="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomInput
                name="first_name"
                label="First Name"
                fullWidth
                placeholder="First Name"
                value={formik.values.first_name}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={formik.handleChange}
                error={
                  formik.touched.first_name && Boolean(formik.errors.first_name)
                }
                helperText={
                  formik.touched.first_name && formik.errors.first_name
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomInput
                name="last_name"
                label="Last Name"
                fullWidth
                placeholder="Last Name"
                value={formik.values.last_name}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={(e) => {
                  formik.setFieldValue('last_name', e.target.value);
                }}
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
                helperText={formik.touched.last_name && formik.errors.last_name}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomInput
                name="gender"
                label="Gender"
                fullWidth
                placeholder="Gender"
                value={formik.values.gender}
                InputLabelProps={{ shrink: true }}
                select
                SelectProps={{
                  native: true,
                }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={formik.handleChange}
                error={formik.touched.gender && Boolean(formik.errors.gender)}
                helperText={formik.touched.gender && formik.errors.gender}
              >
                <option key="Select Gender" value="">
                  Select Gender
                </option>
                <option key="Male" value="Male">
                  Male
                </option>
                <option key="Female" value="Female">
                  Female
                </option>
                <option key="Other" value="Other">
                  Other
                </option>
              </CustomInput>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomInput
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                name="date_of_birth"
                type="date"
                label="Date of Birth"
                placeholder="Date of Birth"
                value={formatDate(formik.values.date_of_birth, 'yyyy-mm-dd')} //2017-06-13T13:00
                onChange={formik.handleChange}
                error={
                  formik.touched.date_of_birth &&
                  Boolean(formik.errors.date_of_birth)
                }
                helperText={
                  formik.touched.date_of_birth && formik.errors.date_of_birth
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomInput
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                name="joined_date"
                type="date"
                label="Joined At"
                placeholder="Joined At"
                value={formatDate(formik.values.joined_date, 'yyyy-mm-dd')} //2017-06-13T13:00
                onChange={formik.handleChange}
                error={
                  formik.touched.joined_date &&
                  Boolean(formik.errors.joined_date)
                }
                helperText={
                  formik.touched.joined_date && formik.errors.joined_date
                }
              />
            </Grid>
            <Grid item xs={12} textAlign="right">
              <CustomButton type="submit" color={'buttonPrimary'}>
                Submit
              </CustomButton>
            </Grid>
          </Grid>
        </CustomBox>
      </form>
      <Divder spacing={1} />
      <CustomBox padding={0}>
        <UserAddressData
          // wishListAllData={singleUserWishlistDetail?.items}
          addressListData={addressList?.items?.results || []}
          // wishlistValue={singleUserWishlistDetail?.items?.wishlist?.rows || []}
          // userId={userId}
          // getWishlistListing={getWishlistListing}
          getListing={getAddressDetails}
          rightSection={renderAddNewAddress}
          handleMoveToCart={handleMoveToCart}
        />
      </CustomBox>
      {openAddEditAddress && (
        <AddEditAddress
          open={openAddEditAddress}
          handleOpen={handleAdd}
          userData={singleUserList?.items}
          getListing={getAddressDetails}
        />
      )}
      {openAddEditWishlist && (
        <AddEditWishlist
          open={openAddEditWishlist}
          handleOpen={handleAddWishlist}
          userData={singleUserList?.items}
          getListing={getWishlistDetails}
          userId={userId}
        />
      )}
      {openAddEditCartList && (
        <AddEditCartList
          open={openAddEditCartList}
          handleOpen={handleAddCartList}
          userData={singleUserList?.items}
          getListing={getCartDetails}
          userId={userId}
        />
      )}
      {openCreateOrderModal && (
        <AddUserOrder
          open={openCreateOrderModal}
          handleOpen={handleOpenUserCreate}
          addressData={selectedAddress}
          setAddressData={setSelectedAddress}
          totalAddressData={addressList?.items?.results}
          getListing={getCartDetails}
          userId={userId}
        />
      )}
    </div>
  );
}

export default UserDetailPage;
