import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  allOrders: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  pendingOrders: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  confirmedOrders: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  pickupList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  rejectedOrders: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  singleOrderDetail: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  proposalListing: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  subscriptionListing: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  ivsDownloadListing: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  ivsOrderListing: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  userSpecificOrderList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
};

export const orderSlice = createSlice({
  name: 'orderListing',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAllOrdersListingLoading: (state, action) => {
      state.allOrders.loading = action.payload;
    },
    setAllOrdersListing: (state, action) => {
      state.allOrders.items = action.payload;
      state.allOrders.error = {
        isError: false,
        message: '',
      };
    },
    setAllOrdersListingError: (state, action) => {
      state.allOrders.items = {};
      state.allOrders.error = action.payload;
    },
    setPendingOrderListingLoading: (state, action) => {
      state.pendingOrders.loading = action.payload;
    },
    setPendingOrderListing: (state, action) => {
      state.pendingOrders.items = action.payload;
      state.pendingOrders.error = {
        isError: false,
        message: '',
      };
    },
    setPendingOrderListingError: (state, action) => {
      state.pendingOrders.items = {};
      state.pendingOrders.error = action.payload;
    },
    setConfirmedOrderListingLoading: (state, action) => {
      state.confirmedOrders.loading = action.payload;
    },
    setConfirmedOrderListing: (state, action) => {
      state.confirmedOrders.items = action.payload;
      state.confirmedOrders.error = {
        isError: false,
        message: '',
      };
    },
    setConfirmedOrderListingError: (state, action) => {
      state.confirmedOrders.items = {};
      state.confirmedOrders.error = action.payload;
    },
    setPickupListLoading: (state, action) => {
      state.pickupList.loading = action.payload;
    },
    setPickupList: (state, action) => {
      state.pickupList.items = action.payload;
      state.pickupList.error = {
        isError: false,
        message: '',
      };
    },
    setPickupListError: (state, action) => {
      state.pickupList.items = {};
      state.pickupList.error = action.payload;
    },
    setRejectedOrderListingLoading: (state, action) => {
      state.rejectedOrders.loading = action.payload;
    },
    setRejectedOrderListing: (state, action) => {
      state.rejectedOrders.items = action.payload;
      state.rejectedOrders.error = {
        isError: false,
        message: '',
      };
    },
    setRejectedOrderListingError: (state, action) => {
      state.rejectedOrders.items = {};
      state.rejectedOrders.error = action.payload;
    },
    setSingleOrderListingLoading: (state, action) => {
      state.singleOrderDetail.loading = action.payload;
    },
    setSingleOrderListing: (state, action) => {
      state.singleOrderDetail.items = action.payload;
      state.singleOrderDetail.error = {
        isError: false,
        message: '',
      };
    },
    setSingleOrderListingError: (state, action) => {
      state.singleOrderDetail.items = {};
      state.singleOrderDetail.error = action.payload;
    },
    setProposalListingLoading: (state, action) => {
      state.proposalListing.loading = action.payload;
    },
    setProposalListing: (state, action) => {
      state.proposalListing.items = action.payload;
      state.proposalListing.error = {
        isError: false,
        message: '',
      };
    },
    setProposalListingError: (state, action) => {
      state.proposalListing.items = {};
      state.proposalListing.error = action.payload;
    },
    setSubscriptionListingLoading: (state, action) => {
      state.subscriptionListing.loading = action.payload;
    },
    setSubscriptionListing: (state, action) => {
      state.subscriptionListing.items = action.payload;
      state.subscriptionListing.error = {
        isError: false,
        message: '',
      };
    },
    setSubscriptionListingError: (state, action) => {
      state.subscriptionListing.items = {};
      state.subscriptionListing.error = action.payload;
    },
    setIvsDownloadListingLoading: (state, action) => {
      state.ivsDownloadListing.loading = action.payload;
    },
    setIvsDownloadListing: (state, action) => {
      state.ivsDownloadListing.items = action.payload;
      state.ivsDownloadListing.error = {
        isError: false,
        message: '',
      };
    },
    setIvsDownloadListingError: (state, action) => {
      state.ivsDownloadListing.items = {};
      state.ivsDownloadListing.error = action.payload;
    },
    setIvsOrderListingLoading: (state, action) => {
      state.ivsOrderListing.loading = action.payload;
    },
    setIvsOrderListing: (state, action) => {
      state.ivsOrderListing.items = action.payload;
      state.ivsOrderListing.error = {
        isError: false,
        message: '',
      };
    },
    setIvsOrderListingError: (state, action) => {
      state.ivsOrderListing.items = {};
      state.ivsOrderListing.error = action.payload;
    },
    setUserSpecificOrderListLoading: (state, action) => {
      state.userSpecificOrderList.loading = action.payload;
    },
    setUserSpecificOrderList: (state, action) => {
      state.userSpecificOrderList.items = action.payload;
      state.userSpecificOrderList.error = {
        isError: false,
        message: '',
      };
    },
    setUserSpecificOrderListError: (state, action) => {
      state.userSpecificOrderList.items = {};
      state.userSpecificOrderList.error = action.payload;
    },
  },
});

export default orderSlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setAllOrdersListing,
  setAllOrdersListingError,
  setPendingOrderListing,
  setPendingOrderListingError,
  setConfirmedOrderListing,
  setConfirmedOrderListingError,
  setRejectedOrderListing,
  setRejectedOrderListingError,
  setSingleOrderListing,
  setSingleOrderListingError,
  setProposalListing,
  setProposalListingError,
  setSubscriptionListing,
  setSubscriptionListingError,
  setIvsDownloadListing,
  setIvsDownloadListingError,
  setIvsOrderListing,
  setIvsOrderListingError,
  setAllOrdersListingLoading,
  setConfirmedOrderListingLoading,
  setIvsDownloadListingLoading,
  setIvsOrderListingLoading,
  setSingleOrderListingLoading,
  setPendingOrderListingLoading,
  setProposalListingLoading,
  setRejectedOrderListingLoading,
  setSubscriptionListingLoading,
  setUserSpecificOrderListLoading,
  setUserSpecificOrderList,
  setUserSpecificOrderListError,
  setPickupListLoading,
  setPickupList,
  setPickupListError,
} = orderSlice.actions;

export const orderListingItemsSelector = (state) => state.orderList;
