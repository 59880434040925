import React from 'react';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import AllKapanList from './AllKapanList';

function KapanTabBody({ activeTab }) {
  return (
    <CustomBox
      padding={0}
      sx={{
        minHeight: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {activeTab == 1 && <AllKapanList />}
    </CustomBox>
  );
}

export default KapanTabBody;
