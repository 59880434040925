import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { errorMessage } from '../lib/helper';
import { STATUS_200, STATUS_300 } from '../data/constants';
import {
  setLoading,
  setAllUserListing,
  setAllUserListingLoading,
  setAllUserListingError,
  setSingleUserListing,
  setSingleUserListingLoading,
  setSingleUserListingError,
  setAddressListing,
  setAddressListingLoading,
  setAddressListingError,
  setWishlistListing,
  setWishlistListingLoading,
  setWishlistListingError,
  setCartListing,
  setCartListingLoading,
  setCouponsListing,
  setCouponsListingLoading,
  setCouponsListingError,
  setCartListingError,
} from '../redux/user';

export const getAllUserListing = (payload, isSingleUser) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(
          isSingleUser
            ? setSingleUserListingLoading(true)
            : setAllUserListingLoading(true),
        );
        const resp = await axios.get(`${ApiConfig.adminUsersList}`, {
          params: payload,
        });
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(
            isSingleUser
              ? setSingleUserListing(resp.data.data)
              : setAllUserListing(resp.data.data),
          );
          dispatch(
            isSingleUser
              ? setSingleUserListingLoading(false)
              : setAllUserListingLoading(false),
          );
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          isSingleUser
            ? setSingleUserListingError({
                isError: true,
                message: errorMessage(e),
              })
            : setAllUserListingError({
                isError: true,
                message: errorMessage(e),
              }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(
          isSingleUser
            ? setSingleUserListingLoading(false)
            : setAllUserListingLoading(false),
        );
      }
    };
  }
};

export const CreateUser = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.adminCreateUser}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const updateUser = async (payload, userId) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.put(
        `${ApiConfig.adminUpdateUser}/${userId}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
    }
  }
};

export const deleteUser = async (userId) => {
  if (userId !== undefined) {
    try {
      const resp = await axios.delete(`${ApiConfig.adminDeleteUser}/${userId}`);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const getAllAddressListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setAddressListingLoading(true));
        const resp = await axios.get(`${ApiConfig.userAddressList}`, {
          params: payload,
        });
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setAddressListing(resp.data.data));
          dispatch(setAddressListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setAddressListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setAddressListingLoading(false));
      }
    };
  }
};

export const CreateAddress = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.addAddress}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const updateAddress = async (payload, addressId) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.put(
        `${ApiConfig.editAddress}/${addressId}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const deleteAddress = async (addressId) => {
  if (addressId !== undefined) {
    try {
      const resp = await axios.delete(
        `${ApiConfig.deleteAddress}/${addressId}`,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const addRemoveWishlist = async (productId, userId) => {
  if (productId !== undefined && userId !== undefined) {
    try {
      const resp = await axios.put(
        `${ApiConfig.addRemoveWishlist}/${productId}`,
        {},
        {
          params: userId,
        },
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const getAllAWishlistListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setWishlistListingLoading(true));
        const resp = await axios.get(`${ApiConfig.wishlist}`, {
          params: payload,
        });
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setWishlistListing(resp.data.data));
          dispatch(setWishlistListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setWishlistListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setWishlistListingLoading(false));
      }
    };
  }
};

export const getUserCartDetail = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setCartListingLoading(true));
        const resp = await axios.get(`${ApiConfig.userCartList}`, {
          params: payload,
        });
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setCartListing(resp.data.data));
          dispatch(setCartListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setCartListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setCartListingLoading(false));
      }
    };
  }
};
export const GetPinCodeData = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.get(
        `${ApiConfig.getAddressByPincode}/${payload}`,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return resp?.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};
export const addToCartUser = async (payload, productId) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.put(
        `${ApiConfig.addToCart}/${productId}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const updateToCartUser = async (payload, addressId) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.put(
        `${ApiConfig.editToCart}/${addressId}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const deleteFromCart = async (addressId) => {
  if (addressId !== undefined) {
    try {
      const resp = await axios.delete(
        `${ApiConfig.removeFromCart}/${addressId}`,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const getAllCouponDetails = () => {
  // if (payload !== undefined) {
  return async (dispatch) => {
    try {
      dispatch(setCouponsListingLoading(true));
      const resp = await axios.get(`${ApiConfig.couponsList}`);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        dispatch(setCouponsListing(resp.data.data));
        dispatch(setCouponsListingLoading(false));
      } else {
        throw resp;
      }
    } catch (e) {
      dispatch(
        setCouponsListingError({
          isError: true,
          message: errorMessage(e),
        }),
      );
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      dispatch(setCouponsListingLoading(false));
    }
  };
  // }
};
