import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DATA_LIMIT } from '../../data/constants';
import {
  downloadBlobXLSFile,
  formatDate,
  getNewParameters,
  getObjectSubset,
  getParameters,
} from '../../lib/helper';
import { userItemsSelector } from '../../redux/user';
import { customHistory } from '../../Route/CustomBrowserRouter';
import ConfirmationModal from '../Modals/ConfirmationModal';
import { getAllUserListing, deleteUser } from '../../Services/userApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';
import Loader from '../CommonComp/CustomLoading/Loader';
import {
  getallGalexyPlanningListing,
  getAllGalexyPlanningListing,
} from '../../Services/srmApis';
import { srmItemSelector } from '../../redux/srm';

const limit = DATA_LIMIT;

const column = [
  {
    label: 'Employee Name',
    id: 'employeeId',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData?.name || '-'}
      </span>
    ),
  },
  {
    label: 'Kapan Id',
    id: 'kapanId',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
      <Link
        className="textDecoration-none link-text"
        to={`/kapan/kapanDetails/${columnData?._id}`}
      >
        <Typography
          variant="span"
          style={{ whiteSpace: 'nowrap' }}
          // className={'link-text'}
          // onClick={() => {
          //   customHistory.push(`order/orderDetails/${columnData}`);
          // }}
        >
          {columnData?.name}
        </Typography>
      </Link>
    ),
  },
  {
    label: 'Created Date',
    id: 'createdAt',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(columnData, 'dd-mm-yyyy hh:mm 12h') || '-'}
      </span>
    ),
  },
  {
    label: 'Carat',
    id: 'plannigCarat',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || '-'}
      </span>
    ),
  },
];

function AllGalexyPlanningList() {
  const [tableListing, setTableListing] = useState([]);
  const [expLoading, setExpLoading] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [page, setPage] = useState(1);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [allParams, setAllParams] = useState(getParameters());
  const { allUserList } = useSelector(userItemsSelector);
  const { allGalexyPlanningList } = useSelector(srmItemSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      ...allParams,
      ...params,
    };
    dispatch(getAllUserListing(payload));
    dispatch(getAllGalexyPlanningListing(payload));
    setPage(Number(payload.page));
  };

  useEffect(() => {
    getListing();
  }, []);

  // useEffect(() => {
  //   let params = getParameters();
  //   setAllParams(params);
  // }, [location?.search]);

  // useEffect(() => {
  //   let data = getNewParameters(allParams);
  //   if (data?.search !== location.search) {
  //     navigate(data.newUrl, { replace: false });
  //     if (data?.parameters?.page) {
  //       setPage(Number(data?.parameters?.page));
  //     }
  //   }
  //   getListing();
  // }, [allParams]);
  //making data format for table input
  useEffect(() => {
    if (allGalexyPlanningList?.items?.length >= 0) {
      setTableListing(allGalexyPlanningList?.items);
    }
  }, [allGalexyPlanningList.items]);

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton
            onClick={async () => {
              handleCofirmModalOpen(true);
              setDeleteData(data?._id);
            }}
            disabled={true}
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              bgcolor: 'buttonDanger.main',
              color: 'buttonSecondary.contrastText',
            }}
          >
            Delete
          </CustomButton>
        </div>
      </>
    );
  };
  const handleDelete = async (value) => {
    try {
      if (value && deleteData) {
        const resp = await deleteUser(deleteData);
        if (resp) {
          getListing();
        }
      } else {
        setDeleteData(null);
      }
    } catch (e) {}
  };

  const handleCofirmModalOpen = (value) => {
    setConfirmDelete(value);
  };
  // const actionHead = (data) => {
  //   return (<div style={{display: "flex"}}>
  //      hi
  //   </div>);
  // };

  return (
    <>
      {allGalexyPlanningList?.loading && <Loader />}
      <MatTable
        loading={allGalexyPlanningList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        // requestSearch={requestSearch}
        // cancelSearch={cancelSearch}
        // onApplyFilter={(data = {}) => {
        //   getListing({
        //     page: String(1),
        //     start_date: '',
        //     end_date: '',
        //     ...getObjectSubset(data, 'start_date', 'end_date'),
        //   });
        //   if (Object.keys(data).length > 0) {
        //     setAllParams({
        //       ...allParams,
        //       page: String(1),
        //       ...getObjectSubset(data, 'start_date', 'end_date'),
        //     });
        //   } else {
        //     setAllParams({
        //       page: String(1),
        //     });
        //   }
        // }}
        // showFilterButton={true}
        // showSerialNo={false}
        action={actionBody}
        // actionHead={actionHead}
        page={page}
        // changeFilter={changeFilter}
        // handleFilterPopup={toggleFilters}
        perPage={limit}
        // setFilters={setFilters}
        // filters={filters}
        total={allGalexyPlanningList?.items?.totalResults || 0}
        // defaultSort="transactionTime"
        // defaultSortOrder={isMobile ? sortOrder : 'desc'}
        // searchTerm={searchTerm}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          // setAllParams({
          //   ...allParams,
          //   page: String(1),
          //   search: searchTerm,
          // });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          // setAllParams({
          //   ...allParams,
          //   page: String(newPage),
          // });
        }}
        checkboxEnable={false}
        toolbarEnable={true}
        // toolBarButton={() => {
        //   return (
        //     <CustomButton
        //       sx={(theme) => ({
        //         fontSize: '12px',
        //         whiteSpace: 'nowrap',
        //         bgcolor: 'buttonSecondary.main',
        //         color: 'buttonSecondary.contrastText',
        //         [theme.breakpoints.down('md')]: {
        //           flex: 1,
        //         },
        //       })}
        //       onClick={() => {
        //         getExportFile();
        //       }}
        //       loading={expLoading}
        //     >
        //       Export XLS
        //     </CustomButton>
        //   );
        // }}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter
        onDelete={() => {}}
        dropdownFilter={true}
        // dropdownFilterOptions={ContactUsFiltersData.related_to}
      />
      {confirmDelete && (
        <ConfirmationModal
          open={confirmDelete}
          handleConfirm={handleDelete}
          setOpen={handleCofirmModalOpen}
        />
      )}
    </>
  );
}

export default AllGalexyPlanningList;
