import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputLabel,
  useTheme,
} from '@mui/material';
import React, { useRef } from 'react';
import makeAnimated from 'react-select/animated';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';

function CustomMultiSelect({
  width,
  handleChange,
  selectOptions,
  error,
  helperText,
  name,
  controlStyle,
  dropDownZIndex,
  label,
  labelShrink,
  ...props
}) {
  const theme = useTheme();
  const asyncRef = useRef(null);
  const animatedComponents = makeAnimated();
  const blurAsync = () => {
    asyncRef.current?.blur();
  };
  return (
    <div style={{ width: width || 450 }}>
      <FormControl
        required
        error={error}
        sx={{
          width: '100%',
        }}
        component="fieldset"
        variant="standard"
      >
        {/* {label && <FormLabel required="false">{label}</FormLabel>} */}
        <InputLabel
          shrink={labelShrink}
          sx={{
            zIndex: 1,
            top: -6,
            left: 10,
            padding: '0 6px',
            background: '#fff',
          }}
          required={props.required}
        >
          {label}
        </InputLabel>
        {/* <AsyncSelect
          isMulti
          cacheOptions
          defaultOptions
          ref={asyncRef}
          loadOptions={promiseOptions}
          onChange={(e) => {
            handleChange(e);
            blurAsync();
          }}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: dropDownZIndex }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: errorStatus
                ? '#d32f2f'
                : state.isFocused
                ? theme.palette.appColor.contrastText
                : theme.palette.appColor.contrastText,
              borderWidth: state.isFocused ? '2px' : '1px',
              boxShadow: '0 0 0 0',
              borderRadius: theme.shape.borderRadius(8),
              padding: 1.5,
              ...controlStyle,
            }),
          }}
          maxMenuHeight={200}
          {...props}
        /> */}
        <Select
          cacheOptions
          defaultOptions
          menuPortalTarget={document.body}
          ref={asyncRef}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: dropDownZIndex }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: error
                ? '#d32f2f'
                : state.isFocused
                ? theme.palette.appColor.contrastText
                : theme.palette.appColor.contrastText,
              borderWidth: state.isFocused ? '2px' : '1px',
              boxShadow: '0 0 0 0',
              borderRadius: theme.shape.borderRadius(8),
              padding: 1.5,
              ...controlStyle,
            }),
          }}
          closeMenuOnSelect={false}
          components={animatedComponents}
          onChange={(e) => {
            handleChange(e);
            blurAsync();
          }}
          isMulti
          options={selectOptions}
          maxMenuHeight={400}
          {...props}
        />
        {error ? <FormHelperText>{helperText}</FormHelperText> : ''}
      </FormControl>
    </div>
  );
}

export default React.memo(CustomMultiSelect);

CustomMultiSelect.defaultProps = {
  selectOptions: [],
  width: 450,
  dropDownZIndex: 100,
  handleChange: () => {},
  label: '',
  labelShrink: false,
  required: false,
};
