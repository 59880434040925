import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  allStonesList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  singleStonesList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  allKapanList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  singleKapanList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  allDepartmentList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  allEmployeeList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  allGalexyPlanningList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  allSingleGalexyPlanningList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
};

export const srmSlice = createSlice({
  name: 'srm',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setStonesListing: (state, action) => {
      state.allStonesList.items = action.payload;
      state.allStonesList.error = {
        isError: false,
        message: '',
      };
    },
    setStonesListingLoading: (state, action) => {
      state.allStonesList.loading = action.payload;
    },
    setStonesListingError: (state, action) => {
      state.allStonesList.items = {};
      state.allStonesList.error = action.payload;
    },
    setSingleStonesListing: (state, action) => {
      state.singleStonesList.items = action.payload;
      state.singleStonesList.error = {
        isError: false,
        message: '',
      };
    },
    setSingleStonesListingLoading: (state, action) => {
      state.singleStonesList.loading = action.payload;
    },
    setSingleStonesListingError: (state, action) => {
      state.singleStonesList.items = {};
      state.singleStonesList.error = action.payload;
    },
    setKapanListing: (state, action) => {
      state.allKapanList.items = action.payload;
      state.allKapanList.error = {
        isError: false,
        message: '',
      };
    },
    setKapanListingLoading: (state, action) => {
      state.allKapanList.loading = action.payload;
    },
    setKapanListingError: (state, action) => {
      state.allKapanList.items = {};
      state.allKapanList.error = action.payload;
    },
    setSingleKapanListing: (state, action) => {
      state.singleKapanList.items = action.payload;
      state.singleKapanList.error = {
        isError: false,
        message: '',
      };
    },
    setSingleKapanListingLoading: (state, action) => {
      state.singleKapanList.loading = action.payload;
    },
    setSingleKapanListingError: (state, action) => {
      state.singleKapanList.items = {};
      state.singleKapanList.error = action.payload;
    },
    setAllDepartmentListing: (state, action) => {
      state.allDepartmentList.items = action.payload;
      state.allDepartmentList.error = {
        isError: false,
        message: '',
      };
    },
    setAllDepartmentListingLoading: (state, action) => {
      state.allDepartmentList.loading = action.payload;
    },
    setAllDepartmentListingError: (state, action) => {
      state.allDepartmentList.items = {};
      state.allDepartmentList.error = action.payload;
    },
    setEmployeeListing: (state, action) => {
      state.allEmployeeList.items = action.payload;
      state.allEmployeeList.error = {
        isError: false,
        message: '',
      };
    },
    setEmployeeListingLoading: (state, action) => {
      state.allEmployeeList.loading = action.payload;
    },
    setEmployeeListingError: (state, action) => {
      state.allEmployeeList.items = {};
      state.allEmployeeList.error = action.payload;
    },
    setGalexyPlanningListing: (state, action) => {
      state.allGalexyPlanningList.items = action.payload;
      state.allGalexyPlanningList.error = {
        isError: false,
        message: '',
      };
    },
    setGalexyPlanningListingLoading: (state, action) => {
      state.allGalexyPlanningList.loading = action.payload;
    },
    setGalexyPlanningListingError: (state, action) => {
      state.allGalexyPlanningList.items = {};
      state.allGalexyPlanningList.error = action.payload;
    },
    setSingleGalexyPlanningListing: (state, action) => {
      state.allSingleGalexyPlanningList.items = action.payload;
      state.allSingleGalexyPlanningList.error = {
        isError: false,
        message: '',
      };
    },
    setSingleGalexyPlanningListingLoading: (state, action) => {
      state.allSingleGalexyPlanningList.loading = action.payload;
    },
    setSingleGalexyPlanningListingError: (state, action) => {
      state.allSingleGalexyPlanningList.items = {};
      state.allSingleGalexyPlanningList.error = action.payload;
    },
  },
});

export default srmSlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setStonesListing,
  setStonesListingLoading,
  setStonesListingError,
  setSingleStonesListing,
  setSingleStonesListingLoading,
  setSingleStonesListingError,
  setKapanListing,
  setKapanListingLoading,
  setKapanListingError,
  setSingleKapanListing,
  setSingleKapanListingLoading,
  setSingleKapanListingError,
  setAllDepartmentListing,
  setAllDepartmentListingLoading,
  setAllDepartmentListingError,
  setEmployeeListing,
  setEmployeeListingLoading,
  setEmployeeListingError,
  setGalexyPlanningListing,
  setGalexyPlanningListingLoading,
  setGalexyPlanningListingError,
  setSingleGalexyPlanningListing,
  setSingleGalexyPlanningListingLoading,
  setSingleGalexyPlanningListingError,
} = srmSlice.actions;

export const srmItemSelector = (state) => state.srm;
