import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import CustomInput from '../../CommonComp/CustomInput/CustomInput';
import CustomButton from '../../CommonComp/Buttons/CustomButton';
import CustomCheckbox from '../../CommonComp/CustomInput/CheckboxField';
import { formatDate } from '../../../lib/helper';
import CustomPhoneInput from '../../CommonComp/CustomInput/CustomPhoneInput';
import {
  CreateAddress,
  GetPinCodeData,
  updateAddress,
} from '../../../Services/userApis';
import { AddNewKapanDetail } from '../../../Services/srmApis';

function AddEditKapanDetails({
  open,
  handleOpen,
  userData,
  stoneDetails,
  getListing,
  selectedData,
  editMode,
}) {
  const [loading, setLoading] = useState(false);
  const [kapanPercentage, setKapanPercentage] = useState(0);
  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    let resp = editMode
      ? await updateAddress(values, selectedData?._id)
      : await AddNewKapanDetail({ ...values, stoneId: userData?._id });
    if (resp) {
      resetForm({
        name: '',
        carat: '',
      });
      getListing();
      handleOpen(false);
    }
    setLoading(false);
  };
  const handleChangeMobile = (val) => {
    formik.setFieldValue('mobileNo', val);
  };
  const formik = useFormik({
    initialValues: {
      name: editMode ? selectedData?.name : '',
      carat: editMode ? selectedData?.carat : '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string('Address Line 2 is Invalid'),
      carat: Yup.string().required('Landmark is required'),
    }),
    onSubmit: handleConfirm,
  });
  console.log('stoneDetails', stoneDetails);
  console.log('selectedData', selectedData);
  console.log('userData?.stock', userData?.stock, 'selectedData', selectedData);
  return (
    <div>
      <Dialog
        maxWidth={'md'}
        fullWidth
        open={open}
        onClose={() => {
          handleOpen(false);
        }}
      >
        <DialogTitle variant="h3">
          {editMode ? 'Edit Kapan Details' : 'Add New Kapan Details'}
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            // sx={{
            //   width: 400
            // }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="h5">
                  Avaialble Carat:-{' '}
                  {userData?.stock || selectedData?.stoneData?.stock}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="h5">
                  Kapan Percentage:- {kapanPercentage}%
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="name"
                  label="Kapan Name"
                  placeholder="Kapan Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="carat"
                  label="Kapan Carat"
                  placeholder="Kapan Carat"
                  value={formik.values.carat}
                  type="number"
                  onChange={(e) => {
                    console.log('e', e);
                    if (
                      Number(e.target.value) <= Number(userData?.totalCarat)
                    ) {
                      formik.setFieldValue('carat', e.target.value);
                      console.log('selectedData');
                      const percentageFromTotalCarat = Number(
                        (Number(e.target.value) * Number(100)) /
                          Number(userData?.totalCarat),
                      );
                      setKapanPercentage(Number(percentageFromTotalCarat));
                    }
                  }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                    inputProps: { min: 0, max: Number(userData?.stock) },
                  }}
                  error={formik.touched.carat && Boolean(formik.errors.carat)}
                  helperText={formik.touched.carat && formik.errors.carat}
                />
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Confirm
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    email: '',
                    status: '',
                    nextFollowUpsDate: '',
                    heading: '',
                    description: '',
                    createdBy: '',
                  });
                  handleOpen(false);
                }}
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddEditKapanDetails;
