import { configureStore } from '@reduxjs/toolkit';

import loginReducer from './login';
import userReducer from './user';
import categoryReducer from './category';
import dashboardReducer from './dashboard';
import productReducer from './product';
import couponReducer from './coupon';
import appSettingReducer from './appSetting';
import orderListingReducer from './orderListing';
import srmReducer from './srm';

export const store = configureStore({
  reducer: {
    loginUserData: loginReducer,
    user: userReducer,
    product: productReducer,
    category: categoryReducer,
    dashboardCount: dashboardReducer,
    coupon: couponReducer,
    appSetting: appSettingReducer,
    orderList: orderListingReducer,
    srm: srmReducer,
  },
});
