import React, { useState } from 'react';
import CustomButton from '../../Components/CommonComp/Buttons/CustomButton';
import MainLayout from '../Layouts/MainLayout';
import GalexyPlanning from './GalexyPlanning';

function GalexyPlanningContainer() {
  const [navParams, setNavParams] = useState({
    navTitle: 'All Galexy Planning Data',
    navSubTitle: 'Welcome to Cotton Slice',
  });
  const [addUserModal, setAddUserModal] = useState(false);
  const handleAddUserModal = (value = false) => {
    setAddUserModal(value);
  };

  const handleNavParams = (obj) => {
    setNavParams({
      ...navParams,
      ...obj,
    });
  };
  return (
    <MainLayout
      navTitle={navParams.navTitle}
      navSubTitle={navParams.navSubTitle}
    >
      <GalexyPlanning
        handleNavParams={handleNavParams}
        addUserModal={addUserModal}
        handleAddUserModal={handleAddUserModal}
      />
    </MainLayout>
  );
}

export default GalexyPlanningContainer;
