import React, { useState } from 'react';
import CustomButton from '../../Components/CommonComp/Buttons/CustomButton';
import MainLayout from '../Layouts/MainLayout';
import Stones from './Stones';

function StoneContainer() {
  const [navParams, setNavParams] = useState({
    navTitle: 'All Stone Data',
    navSubTitle: 'Welcome to SRM',
  });
  const [addUserModal, setAddUserModal] = useState(false);
  const handleAddUserModal = (value = false) => {
    setAddUserModal(value);
  };

  const handleNavParams = (obj) => {
    setNavParams({
      ...navParams,
      ...obj,
    });
  };
  return (
    <MainLayout
      navTitle={navParams.navTitle}
      navSubTitle={navParams.navSubTitle}
      navBarRightComp={
        <CustomButton
          sx={{ padding: '12px 15px', ml: (theme) => theme.spacing(2) }}
          color={'buttonPrimary'}
          onClick={() => handleAddUserModal(true)}
        >
          Add New Stone
        </CustomButton>
      }
    >
      <Stones
        handleNavParams={handleNavParams}
        addUserModal={addUserModal}
        handleAddUserModal={handleAddUserModal}
      />
    </MainLayout>
  );
}

export default StoneContainer;
