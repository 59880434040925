import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  allAdminUsersList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
};

export const adminUsersSlice = createSlice({
  name: 'adminUsers',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAllAdminUsersListing: (state, action) => {
      state.allAdminUsersList.items = action.payload;
      state.allAdminUsersList.error = {
        isError: false,
        message: '',
      };
    },
    setAllAdminUsersListingLoading: (state, action) => {
      state.allAdminUsersList.loading = action.payload;
    },
    setAllAdminUsersListingError: (state, action) => {
      state.allAdminUsersList.items = {};
      state.allAdminUsersList.error = action.payload;
    },
  },
});

export default adminUsersSlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setAllAdminUsersListing,
  setAllAdminUsersListingError,
  setAllAdminUsersListingLoading,
} = adminUsersSlice.actions;

export const adminUsersItemsSelector = (state) => state.adminUsers;
