import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../../CommonComp/CustomInput/CustomInput';
import CustomButton from '../../CommonComp/Buttons/CustomButton';
import CustomCheckbox from '../../CommonComp/CustomInput/CheckboxField';
import { formatDate } from '../../../lib/helper';
import CustomPhoneInput from '../../CommonComp/CustomInput/CustomPhoneInput';
import {
  CreateAddress,
  GetPinCodeData,
  updateAddress,
} from '../../../Services/userApis';

function AddEditAddress({
  open,
  handleOpen,
  userData,
  getListing,
  selectedData,
  editMode,
}) {
  const [loading, setLoading] = useState(false);
  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    let resp = editMode
      ? await updateAddress(values, selectedData?._id)
      : await CreateAddress(values);
    if (resp) {
      resetForm({
        userId: '',
        email: '',
        name: '',
        mobileNo: '',
        type: '',
        pinCode: '',
        city: '',
        state: '',
        addressLine1: '',
        addressLine2: '',
        landmark: '',
        isDefault: false,
      });
      getListing();
      handleOpen(false);
    }
    setLoading(false);
  };
  const handleChangeMobile = (val) => {
    formik.setFieldValue('mobileNo', val);
  };
  const formik = useFormik({
    initialValues: {
      userId: editMode ? selectedData?.user : userData ? userData?._id : '',
      email: editMode ? selectedData?.email : userData ? userData?.email : '',
      name: editMode
        ? selectedData?.firstName ||
          (selectedData?.lastName &&
            selectedData?.firstName + ' ' + selectedData?.lastName)
        : userData
        ? userData?.firstName ||
          (userData?.lastName && userData?.firstName + ' ' + userData?.lastName)
        : '',
      mobileNo: editMode
        ? selectedData?.mobileNo
        : userData
        ? userData?.mobileNo
        : '',
      type: editMode ? selectedData?.type : '',
      pinCode: editMode ? selectedData?.pinCode : '',
      city: editMode ? selectedData?.city : '',
      state: editMode ? selectedData?.state : '',
      addressLine1: editMode ? selectedData?.addressLine1 : '',
      addressLine2: editMode ? selectedData?.addressLine2 : '',
      landmark: editMode ? selectedData?.landmark : '',
      isDefault: selectedData?.isDefault || false,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Email is Invalid')
        .required('Please enter Email'),
      name: Yup.string().required('Name is required'),
      mobileNo: Yup.string()
        .max(18, 'Mobile Number is Invalid')
        .min(10, 'Mobile Number is Invalid')
        .required('Mobile Number is required'),
      type: Yup.string().required('Type is required'),
      pinCode: Yup.number('Pin Code is Invalid').required(
        'Pincode is required',
      ),
      city: Yup.string().required('City is required'),
      state: Yup.string().required('State is required'),
      addressLine1: Yup.string().required('Address Line 1 is required'),
      addressLine2: Yup.string('Address Line 2 is Invalid'),
      landmark: Yup.string().required('Landmark is required'),
      isDefault: Yup.boolean('Is Default is Invalid').required(
        'Is Default is required',
      ),
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        fullWidth
        open={open}
        onClose={() => {
          handleOpen(false);
        }}
      >
        <DialogTitle variant="h3">
          {editMode ? 'Edit Address' : 'Add Address'}
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            // sx={{
            //   width: 400
            // }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={4}>
                <CustomPhoneInput
                  label="Mobile"
                  id="mobileNo"
                  name="mobileNo"
                  className="main-login-form"
                  fullWidth
                  placeholder="Mobile Number"
                  native={true}
                  value={formik.values.mobileNo}
                  defaultCountry={`in`}
                  country={`in`}
                  divStyle={{
                    paddingTop: 4,
                  }}
                  variant="outlined"
                  onChange={(newValue, info) => {
                    handleChangeMobile(newValue);
                  }}
                  onlyCountries={['in']}
                  error={
                    formik.touched.mobileNo && Boolean(formik.errors.mobileNo)
                  }
                  helperText={formik.touched.mobileNo && formik.errors.mobileNo}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="email"
                  label="Email"
                  placeholder="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="name"
                  label="Name"
                  placeholder="Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  name="type"
                  label="Address Type"
                  fullWidth
                  placeholder="Address Type"
                  value={formik.values.type}
                  InputLabelProps={{ shrink: true }}
                  select
                  SelectProps={{
                    native: true,
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  onChange={formik.handleChange}
                  error={formik.touched.type && Boolean(formik.errors.type)}
                  helperText={formik.touched.type && formik.errors.type}
                >
                  <option key="Select Gender" value="">
                    Select Address Type
                  </option>
                  <option key="Home" value="Home">
                    Home
                  </option>
                  <option key="Office" value="Office">
                    Office
                  </option>
                  <option key="Other" value="Other">
                    Other
                  </option>
                </CustomInput>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="pinCode"
                  label="Pin Code"
                  placeholder="Pin Code"
                  value={formik.values.pinCode}
                  onChange={async (e) => {
                    formik.setFieldValue('pinCode', e.target.value);
                    const valuesData = e.target.value;
                    if (valuesData?.length === 6) {
                      const respo = await GetPinCodeData(e.target.value);
                      if (respo) {
                        formik.setFieldValue('city', respo?.data?.city);
                        formik.setFieldValue('state', respo?.data?.state);
                      }
                    }
                  }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.pinCode && Boolean(formik.errors.pinCode)
                  }
                  helperText={formik.touched.pinCode && formik.errors.pinCode}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="city"
                  label="City"
                  placeholder="City"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="state"
                  label="State"
                  placeholder="State"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomCheckbox
                  name="isDefault"
                  label={'Is Default'}
                  labelPlacement={'end'}
                  subLableText={'Checked'}
                  value={formik.values.isDefault == 1 ? true : false}
                  handleChange={(e) => {
                    formik.setFieldValue(
                      'isDefault',
                      e.target.checked == true ? true : false,
                    );
                  }}
                  error={
                    formik.touched.isDefault && Boolean(formik.errors.isDefault)
                  }
                  helperText={
                    formik.touched.isDefault && formik.errors.isDefault
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="addressLine1"
                  label="Address Line 1"
                  placeholder="Address Line 1"
                  value={formik.values.addressLine1}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.addressLine1 &&
                    Boolean(formik.errors.addressLine1)
                  }
                  helperText={
                    formik.touched.addressLine1 && formik.errors.addressLine1
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="addressLine2"
                  label="Address Line 2"
                  placeholder="Address Line 2"
                  value={formik.values.addressLine2}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.addressLine2 &&
                    Boolean(formik.errors.addressLine2)
                  }
                  helperText={
                    formik.touched.addressLine2 && formik.errors.addressLine2
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="landmark"
                  label="Landmark"
                  placeholder="Landmark"
                  value={formik.values.landmark}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.landmark && Boolean(formik.errors.landmark)
                  }
                  helperText={formik.touched.landmark && formik.errors.landmark}
                />
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Confirm
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    email: '',
                    status: '',
                    nextFollowUpsDate: '',
                    heading: '',
                    description: '',
                    createdBy: '',
                  });
                  handleOpen(false);
                }}
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddEditAddress;
