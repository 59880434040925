import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import { userItemsSelector } from '../../redux/user';
import CustomPhoneInput from '../CommonComp/CustomInput/CustomPhoneInput';
import { DATA_LIMIT } from '../../data/constants';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import {
  getAllAWishlistListing,
  getAllAddressListing,
  getAllUserListing,
  getUserCartDetail,
  updateUser,
} from '../../Services/userApis';
import { formatDate } from '../../lib/helper';
import { toast } from 'react-toastify';
import UserAddressData from './GelexyData';
import Divder from '../CommonComp/Divder';
import AddEditAddress from '../Modals/User/AddEditAddress';
import WishlistData from './WishlistData';
import AddEditWishlist from '../Modals/User/AddEditWishlist';
import AddToCartData from './AddToCartData';
import AddEditCartList from '../Modals/User/AddEditCartList';
import AddUserOrder from '../Modals/User/AddUserOrder';
import OrderUserListing from './OrderUserListing';
import {
  getOrderDataList,
  getUserSpecificOrderList,
} from '../../Services/orderApis';
import { orderListingItemsSelector } from '../../redux/orderListing';
import Loader from '../CommonComp/CustomLoading/Loader';
import StoneKapanData from './GelexyData';
import AddEditKapanDetails from '../Modals/Stones/AddEditKapanDetails';
import GelexyData from './GelexyData';
import {
  getGalexyDataFromKapanListing,
  getSingleKapanListing,
} from '../../Services/srmApis';
import { srmItemSelector } from '../../redux/srm';

const limit = DATA_LIMIT;

function KapanDetailPage({ userId }) {
  const { addressList, singleUserList, wishlistData, cartList } =
    useSelector(userItemsSelector);
  const { userSpecificOrderList } = useSelector(orderListingItemsSelector);
  const { singleKapanList } = useSelector(srmItemSelector);
  const dispatch = useDispatch();
  const [openAddEditAddress, setOpenAddEditAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [galexyData, setGalexyData] = useState([]);
  const [openAddEditWishlist, setOpenAddEditWishlist] = useState(false);
  const [openCreateOrderModal, setOpenCreateOrderModal] = useState(false);
  const [openAddEditCartList, setOpenAddEditCartList] = useState(false);
  const handleConfirm = async (values, { resetForm }) => {
    if (values.mobile) {
      let payload = {
        firstName: values?.first_name,
        lastName: values?.last_name,
        email: values?.email,
        gender: values?.gender,
        dob: values?.date_of_birth,
      };
      let resp = await updateUser(payload, values?.user_id);
    }
  };
  const handleAdd = (value) => {
    setOpenAddEditAddress(value);
  };
  const handleAddWishlist = (value) => {
    setOpenAddEditWishlist(value);
  };
  const handleAddCartList = (value) => {
    setOpenAddEditCartList(value);
  };
  const handleOpenUserCreate = (value) => {
    setOpenCreateOrderModal(value);
  };
  const formik = useFormik({
    initialValues: {
      stoneName: singleKapanList?.items?.stoneId?.name || '',
      stoneTotalCarat: singleKapanList?.items?.stoneId?.totalCarat || '',
      kapanDate: singleKapanList?.items?.createdAt || '',
      kapanCarat: singleKapanList?.items?.carat || '',
    },
    enableReinitialize: true,
    onSubmit: handleConfirm,
  });
  console.log('singleKapanListsingleKapanList', singleKapanList);
  const getUserDetails = async () => {
    const payload = {
      page: String(1),
      limit: limit,
      user_id: userId,
    };
    dispatch(getAllUserListing(payload, true));
  };
  const getAddressDetails = async () => {
    const payloadForAddress = {
      page: String(1),
      limit: limit,
      userId: userId,
    };
    dispatch(getSingleKapanListing(userId));
  };
  const handleChangeMobile = (val) => {
    formik.setFieldValue('mobile', val);
  };
  const getGalexy = async () => {
    const dataForGalexy = await getGalexyDataFromKapanListing(userId);
    console.log('dataForGalexydataForGalexy', dataForGalexy?.result);
    setGalexyData(dataForGalexy?.result);
  };
  useEffect(() => {
    getGalexy();
    getAddressDetails();
  }, []);
  const renderAddNewAddress = () => {
    return (
      <CustomButton
        className="buttonDense"
        onClick={() => handleAdd(true)}
        color={'buttonPrimary'}
      >
        Create New Stone Kapan
      </CustomButton>
    );
  };
  const renderAddNewWishlist = () => {
    return (
      <CustomButton
        className="buttonDense"
        onClick={() => handleAddWishlist(true)}
        color={'buttonPrimary'}
      >
        Add New Wishlist
      </CustomButton>
    );
  };
  const renderAddNewCart = () => {
    return (
      <CustomButton
        className="buttonDense"
        onClick={() => handleAddCartList(true)}
        color={'buttonPrimary'}
      >
        Add To Cart
      </CustomButton>
    );
  };
  const handleOpenCreateNewOrder = () => {
    if (addressList?.items?.results?.length === 0) {
      toast.error('Please Add Address First', { autoClose: 2500 });
      handleAdd(true);
    }
    if (addressList && addressList?.items?.results?.length > 0) {
      const defaultAdd = addressList?.items?.results?.find(
        (item) => item?.isDefault,
      );
      if (defaultAdd !== undefined) {
        setSelectedAddress(defaultAdd);
      } else {
        setSelectedAddress(addressList?.items?.results[0]);
      }
      handleOpenUserCreate(true);
    }
  };
  const handleMoveToCart = async (item, index, length) => {
    // let data = [item]
    // setInitialData(data)
    // handleAddToModalOpen(true, 0)
  };
  return (
    <div>
      {(addressList?.loading ||
        singleUserList?.loading ||
        wishlistData?.loading ||
        cartList?.loading) && <Loader />}
      <form onSubmit={formik.handleSubmit}>
        <CustomBox padding={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomInput
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                disabled={true}
                name="date_of_birth"
                type="date"
                label="Stone Added Date"
                placeholder="Stone Added Date"
                value={formatDate(new Date(), 'yyyy-mm-dd')} //2017-06-13T13:00
                onChange={formik.handleChange}
                error={
                  formik.touched.date_of_birth &&
                  Boolean(formik.errors.date_of_birth)
                }
                helperText={
                  formik.touched.date_of_birth && formik.errors.date_of_birth
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomInput
                name="stoneName"
                label="Stone Name"
                fullWidth
                placeholder="Stone Name"
                value={formik.values.stoneName}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={formik.handleChange}
                error={
                  formik.touched.stoneName && Boolean(formik.errors.stoneName)
                }
                helperText={formik.touched.stoneName && formik.errors.stoneName}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomInput
                name="stoneTotalCarat"
                label="Total Stone Carat"
                fullWidth
                placeholder="Total Stone Carat"
                value={formik.values.stoneTotalCarat}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={(e) => {
                  formik.setFieldValue('stoneTotalCarat', e.target.value);
                }}
                error={
                  formik.touched.stoneTotalCarat &&
                  Boolean(formik.errors.stoneTotalCarat)
                }
                helperText={
                  formik.touched.stoneTotalCarat &&
                  formik.errors.stoneTotalCarat
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomInput
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                disabled={true}
                name="kapanDate"
                type="date"
                label="Kapan Date"
                placeholder="Kapan Date"
                value={formatDate(
                  new Date(formik.values.kapanDate),
                  'yyyy-mm-dd',
                )} //2017-06-13T13:00
                onChange={formik.handleChange}
                error={
                  formik.touched.kapanDate && Boolean(formik.errors.kapanDate)
                }
                helperText={formik.touched.kapanDate && formik.errors.kapanDate}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomInput
                name="kapanCarat"
                label="Kapan Carat"
                fullWidth
                placeholder="Kapan Carat"
                value={formik.values.kapanCarat}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={(e) => {
                  formik.setFieldValue('kapanCarat', e.target.value);
                }}
                error={
                  formik.touched.kapanCarat && Boolean(formik.errors.kapanCarat)
                }
                helperText={
                  formik.touched.kapanCarat && formik.errors.kapanCarat
                }
              />
            </Grid>
          </Grid>
        </CustomBox>
      </form>
      <Divder spacing={1} />
      <CustomBox padding={0}>
        <GelexyData
          // wishListAllData={singleUserWishlistDetail?.items}
          addressListData={galexyData || []}
          // wishlistValue={singleUserWishlistDetail?.items?.wishlist?.rows || []}
          // userId={userId}
          // getWishlistListing={getWishlistListing}
          getListing={getGalexy}
          // rightSection={renderAddNewAddress}
          handleMoveToCart={handleMoveToCart}
        />
      </CustomBox>
      {openAddEditAddress && (
        <AddEditKapanDetails
          open={openAddEditAddress}
          handleOpen={handleAdd}
          userData={singleUserList?.items}
          getListing={getAddressDetails}
        />
      )}
      {openAddEditWishlist && (
        <AddEditWishlist
          open={openAddEditWishlist}
          handleOpen={handleAddWishlist}
          userData={singleUserList?.items}
          getListing={getWishlistDetails}
          userId={userId}
        />
      )}
      {openAddEditCartList && (
        <AddEditCartList
          open={openAddEditCartList}
          handleOpen={handleAddCartList}
          userData={singleUserList?.items}
          getListing={getCartDetails}
          userId={userId}
        />
      )}
      {openCreateOrderModal && (
        <AddUserOrder
          open={openCreateOrderModal}
          handleOpen={handleOpenUserCreate}
          addressData={selectedAddress}
          setAddressData={setSelectedAddress}
          totalAddressData={addressList?.items?.results}
          getListing={getCartDetails}
          userId={userId}
        />
      )}
    </div>
  );
}

export default KapanDetailPage;
