import React from 'react';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import AllStonesList from './AllStonesList';

function StoneTabBody({ activeTab }) {
  return (
    <CustomBox
      padding={0}
      sx={{
        minHeight: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {activeTab == 1 && <AllStonesList />}
    </CustomBox>
  );
}

export default StoneTabBody;
