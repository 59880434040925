import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  allProductList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  singleProductList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
};

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAllProductListing: (state, action) => {
      state.allProductList.items = action.payload;
      state.allProductList.error = {
        isError: false,
        message: '',
      };
    },
    setAllProductListingLoading: (state, action) => {
      state.allProductList.loading = action.payload;
    },
    setAllProductListingError: (state, action) => {
      state.allProductList.items = {};
      state.allProductList.error = action.payload;
    },

    setSingleProductListing: (state, action) => {
      state.singleProductList.items = action.payload;
      state.singleProductList.error = {
        isError: false,
        message: '',
      };
    },
    setSingleProductListingLoading: (state, action) => {
      state.singleProductList.loading = action.payload;
    },
    setSingleProductListingError: (state, action) => {
      state.singleProductList.items = {};
      state.singleProductList.error = action.payload;
    },
    clearSingleProductDetails: (state, action) => {
      state.allUserList.items = {};
    },
  },
});

export default productSlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setAllProductListing,
  setAllProductListingLoading,
  setAllProductListingError,
  setSingleProductListing,
  setSingleProductListingLoading,
  setSingleProductListingError,
  clearSingleProductDetails,
} = productSlice.actions;

export const productItemsSelector = (state) => state.product;
