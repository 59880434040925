import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { errorMessage } from '../lib/helper';
import { STATUS_200, STATUS_300 } from '../data/constants';
import {
  setLoading,
  setStonesListing,
  setStonesListingLoading,
  setStonesListingError,
  setSingleStonesListing,
  setSingleStonesListingLoading,
  setSingleStonesListingError,
  setKapanListing,
  setKapanListingLoading,
  setKapanListingError,
  setSingleKapanListing,
  setSingleKapanListingLoading,
  setSingleKapanListingError,
  setAllDepartmentListing,
  setAllDepartmentListingLoading,
  setAllDepartmentListingError,
  setEmployeeListing,
  setEmployeeListingLoading,
  setEmployeeListingError,
  setGalexyPlanningListing,
  setGalexyPlanningListingLoading,
  setGalexyPlanningListingError,
  setSingleGalexyPlanningListing,
  setSingleGalexyPlanningListingLoading,
  setSingleGalexyPlanningListingError,
} from '../redux/srm';
// Srm
// Srm
// Srm
// Srm
// Srm
// Srm
// Srm
// Srm
// Srm
// Srm
// Srm
// Srm
// Srm
// Srm
// Srm

export const getAllDepartmentListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setAllDepartmentListingLoading(true));
        const resp = await axios.get(`${ApiConfig.departmentsListing}`);
        console.log('respresprespresp', resp);
        if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
          dispatch(setAllDepartmentListing(resp.data));
          dispatch(setAllDepartmentListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setAllDepartmentListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setAllDepartmentListingLoading(false));
      }
    };
  }
};

export const getAllEmployeesListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setEmployeeListingLoading(true));
        const resp = await axios.get(`${ApiConfig.employeesListing}`);
        console.log('respresprespresp', resp);
        if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
          dispatch(setEmployeeListing(resp.data));
          dispatch(setEmployeeListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setEmployeeListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setEmployeeListingLoading(false));
      }
    };
  }
};

export const getAllStonesListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setStonesListingLoading(true));
        const resp = await axios.get(`${ApiConfig.stonesListing}`);
        console.log('respresprespresp', resp);
        if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
          dispatch(setStonesListing(resp.data));
          dispatch(setStonesListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setStonesListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setStonesListingLoading(false));
      }
    };
  }
};
export const getSingleStonesListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setSingleStonesListingLoading(true));
        const resp = await axios.get(
          `${ApiConfig.stonesListing}/stoneDetails/${payload}`,
        );
        console.log('respresprespresp', resp);
        if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
          dispatch(setSingleStonesListing(resp.data));
          dispatch(setSingleStonesListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSingleStonesListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setSingleStonesListingLoading(false));
      }
    };
  }
};
export const AddNewStoneDetail = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.stonesListing}`, payload);
      if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
        toast.success('Successfully Created', {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};
export const UpdateStoneDetail = async (payload, stoneId) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.put(
        `${ApiConfig.stonesListing}/${stoneId}`,
        payload,
      );
      if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
        toast.success('Updated Successfully', {
          autoClose: 2500,
        });
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
    }
  }
};

export const DeleteStoneDetail = async (stoneId) => {
  if (stoneId !== undefined) {
    try {
      const resp = await axios.delete(`${ApiConfig.stonesListing}/${stoneId}`);
      if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
        toast.success('Deleted Successfully', {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};
export const getAllKapanListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setKapanListingLoading(true));
        const resp = await axios.get(`${ApiConfig.kapanListing}`);
        console.log('respresprespresp', resp);
        if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
          dispatch(setKapanListing(resp.data));
          dispatch(setKapanListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setKapanListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setKapanListingLoading(false));
      }
    };
  }
};
export const getSingleKapanListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setSingleKapanListingLoading(true));
        const resp = await axios.get(
          `${ApiConfig.kapanListing}/kapanDetails/${payload}`,
        );
        console.log('respresprespresp', resp);
        if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
          dispatch(setSingleKapanListing(resp.data));
          dispatch(setSingleKapanListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSingleKapanListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setSingleKapanListingLoading(false));
      }
    };
  }
};
export const AddNewKapanDetail = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.kapanListing}`, payload);
      if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
        toast.success('Successfully Created', {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};
export const UpdateKapanDetail = async (payload, stoneId) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.put(
        `${ApiConfig.kapanListing}/${stoneId}`,
        payload,
      );
      if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
        toast.success('Updated Successfully', {
          autoClose: 2500,
        });
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
    }
  }
};

export const DeleteKapanDetail = async (stoneId) => {
  if (stoneId !== undefined) {
    try {
      const resp = await axios.delete(`${ApiConfig.kapanListing}/${stoneId}`);
      if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
        toast.success('Deleted Successfully', {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const getAllGalexyPlanningListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setGalexyPlanningListingLoading(true));
        const resp = await axios.get(`${ApiConfig.galexyPlanningListing}`);
        console.log('respresprespresp', resp);
        if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
          dispatch(setGalexyPlanningListing(resp.data?.result));
          dispatch(setGalexyPlanningListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setGalexyPlanningListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setGalexyPlanningListingLoading(false));
      }
    };
  }
};
export const getGalexyDataFromKapanListing = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.get(
        `${ApiConfig.getGelexyDataFromKapanId}/${payload}`,
      );
      console.log('respresprespresp', resp);
      if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
        return resp?.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};
export const getGalexyPlanningListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setSingleKapanListingLoading(true));
        const resp = await axios.get(
          `${ApiConfig.stonesListing}/stoneDetails/${payload}`,
        );
        console.log('respresprespresp', resp);
        if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
          dispatch(setSingleKapanListing(resp.data));
          dispatch(setSingleKapanListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSingleKapanListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setSingleKapanListingLoading(false));
      }
    };
  }
};
export const AddNewGalexyPlanningDetail = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.kapanListing}`, payload);
      if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
        toast.success('Successfully Created', {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};
export const UpdateGalexyPlanningDetail = async (payload, stoneId) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.put(
        `${ApiConfig.kapanListing}/${stoneId}`,
        payload,
      );
      if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
        toast.success('Updated Successfully', {
          autoClose: 2500,
        });
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
    }
  }
};

export const DeleteGalexyPlanningDetail = async (stoneId) => {
  if (stoneId !== undefined) {
    try {
      const resp = await axios.delete(`${ApiConfig.kapanListing}/${stoneId}`);
      if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
        toast.success('Deleted Successfully', {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

// Srm
// Srm
// Srm
// Srm
// Srm
// Srm
// Srm
// Srm
// Srm
// Srm
// Srm
// Srm
// Srm
// Srm
