import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { AppBar, Box, IconButton, Typography, useTheme } from '@mui/material';
import { AppLogoNew } from '../../Asset/svg';
import { IconArr, sideMenu } from '../../data/SideMenu';
import { Link, useParams, useLocation } from 'react-router-dom';
import Lock from '@mui/icons-material/Lock';
import { useAuth } from '../../Context/AuthProvider';
import { getItemFromStorage } from '../../storage/Storage';
import { getAccessMenuList } from '../../Services/adminUsersApis';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { loginItemsSelector } from '../../redux/login';
import { imageListingItemsSelector } from '../../redux/image';
import { menuListingItemsSelector } from '../../redux/userMenus';

const drawerWidth = 210;
const logoWidth = '100%';
const logoHeight = '30px';
const drawerHeight = '95%';


const container = window !== undefined ? () => window().document.body : undefined;

function ListItemLink({ disabled = false, Icon, primary, to }) {
  const location = useLocation();

  const renderLink = React.useMemo(() => {
    return React.forwardRef((itemProps, ref) => (
      <Link to={to} ref={ref} {...itemProps} style={{ background: '#0000' }} />
    ));
  }, [to]);

  const makeMenuIcon = (iconIndex) =>{
    let IconComponent = IconArr[iconIndex]
    //return ''
   return <IconComponent fill={to === location?.pathname ? '#fff' : '#000'} style={{ color: to === location?.pathname ? '#fff' : '#000' }} />
  }

  return (
    <ListItem button disabled={disabled ?? false} component={renderLink}>
      <ListItemButton selected={to === location.pathname} className={'sidebar-item'}>
        {Icon >=0 ? (
          <ListItemIcon sx={{ marginRight: '10px', minWidth: '24px' }}>
            {makeMenuIcon(Icon)}
          </ListItemIcon>
        ) : null}
        <ListItemText primary={primary} classes={{ primary: `fontWeight600 ${to === location.pathname ? "colorWhite" : "colorBlack"}` }} />
      </ListItemButton>
    </ListItem>
  );
}

ListItemLink.defaultProps = {
  disabled: false,
};

export default function Sidebar({mobileOpen, handleDrawerToggle}) {
  const theme = useTheme();
  const [menuList, setMenuList] = useState([]);
   const { userDetails } = useSelector(loginItemsSelector);
  //  const {allMenus}=useSelector(menuListingItemsSelector);
   const allMenus = sideMenu
  
  const [list,setlist]=useState([])
  const { logout } = useAuth();
  
  const handleLogout = () => logout(null);

  useEffect(() => {
    handleAddedMenu()
  }, [])

  const handleAddedMenu = async () => {
    let data = getItemFromStorage('user');
    if (data) {
      let data1 = JSON.parse(data);
      // let payload = { admin_id: obj?.admin_id };
      let resp = data1?.admin_menu;
      if(resp) {
          let array = [...sideMenu];
          array = array?.filter((a) => {
            return resp[a?.value] === '1'
          })
          setMenuList(array);
        
      }
    }
  }


  // useEffect(()=>{
  //   handleSome();
  // },[])

  const handleSome=()=>{
     if(allMenus?.items){
       setlist()
       //let obj=allMenus?.items;
       setlist(allMenus);
     }
  }

  return (
    <>
      <Drawer
        sx={{
          display: { xs: 'none', md: 'block' },
          width: drawerWidth,
          height: drawerHeight,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            height: drawerHeight,
            boxSizing: 'border-box',
            margin: 2,
            backgroundColor: theme.backgroundColor.box.main,
            border: 'none',
            borderRadius: theme.shape.borderRadius(9),
          },
        }}
        variant="permanent"
        anchor="left"
        open
      >
        <List>
          <ListItem key={'AppLogo'}>
            <Box
              sx={{
                width: logoWidth,
                height: logoHeight,
                padding: `0 ${theme.spacing(1)}`,
                marginBottom: theme.spacing(2),
                marginTop: theme.spacing(1.5),
              }}
            >
              <AppLogoNew style={{
                maxWidth: 180,
                maxHeight: 37,
                width: '100%'
              }} />
            </Box>
          </ListItem>
          {allMenus?.map((item, index) => {
            return (
              <ListItemLink
                Icon={item?.icon}
                primary={item?.label}
                to={item?.pathName}
                key={item?.label}
              />
            );
            {
              /* <ListItem key={text}>
            <ListItemButton>
              <ListItemIcon sx={{marginRight: "10px", minWidth: '24px'}}>
                <MailIcon />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem> */
            }
          })}
          <ListItem key={'Logout'}>
            <ListItemButton onClick={() => {
              handleLogout()
            }} className={'sidebar-item'}>
              <ListItemIcon sx={{ marginRight: '10px', minWidth: '24px' }}>
                <Lock style={{ color: "#000" }} />
              </ListItemIcon>
              <ListItemText primary={'Sign Out'} classes={{ primary: `fontWeight600 colorBlack` }} />
            </ListItemButton>
          </ListItem>
          <Box sx={{ marginTop: '50px', padding: '0 30px 30px 30px' }}>
            <Typography variant="12A3" component="div">
              ©PixelDot.com.
              All rights reserved.
            </Typography>
          </Box>
        </List>
      </Drawer>
      <Drawer
        sx={{
          display: { xs: 'block', md: 'none' },
          width: drawerWidth,
          height: drawerHeight,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            height: "100%",
            boxSizing: 'border-box',
            // margin: 2,
            backgroundColor: theme.backgroundColor.box.main,
            border: 'none',
            // borderRadius: theme.shape.borderRadius(9),
          },
        }}
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <List>
          <ListItem key={'AppLogo'}>
            <Box
              sx={{
                width: logoWidth,
                height: logoHeight,
                padding: `0 ${theme.spacing(1)}`,
                marginBottom: theme.spacing(2),
                marginTop: theme.spacing(1.5),
              }}
            >
              <AppLogoNew style={{
                maxWidth: 180,
                maxHeight: 37,
                width: '100%'
              }} />
            </Box>
          </ListItem>
          {allMenus?.map((item, index) => {
            return (
              <ListItemLink
                Icon={item?.icon}
                primary={item?.label}
                to={item?.pathName}
                key={item?.label}
              />
            );
            {
              /* <ListItem key={text}>
            <ListItemButton>
              <ListItemIcon sx={{marginRight: "10px", minWidth: '24px'}}>
                <MailIcon />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem> */
            }
          })}
          <ListItem key={'Logout'}>
            <ListItemButton onClick={() => {
              handleLogout()
            }} className={'sidebar-item'}>
              <ListItemIcon sx={{ marginRight: '10px', minWidth: '24px' }}>
                <Lock style={{ color: "#000" }} />
              </ListItemIcon>
              <ListItemText primary={'Sign Out'} classes={{ primary: `fontWeight600 colorBlack` }} />
            </ListItemButton>
          </ListItem>
          <Box sx={{ marginTop: '50px', padding: '0 30px 30px 30px' }}>
            <Typography variant="12A3" component="div">
              ©PixelDot.com.
              All rights reserved.
            </Typography>
          </Box>
        </List>
      </Drawer>
    </>
  );
}
