import React, { useState } from 'react';
import MainLayout from '../Layouts/MainLayout';
import PickupDetails from './PickupDetails';

function PickupDetailContainer({ isEdit }) {
  return (
    <MainLayout
      navTitle={isEdit ? 'Pickup Details' : 'Pickup Details'}
      navSubTitle={'Welcome to Cotton Slice'}
    >
      <PickupDetails isEdit={isEdit} />
    </MainLayout>
  );
}

export default PickupDetailContainer;
