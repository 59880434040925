import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ApiConfig from '../../config/ApiConfig';
import { DATA_LIMIT } from '../../data/constants';
import {
  deleteAddress,
  deleteFromWishlist,
  userWishlistApi,
} from '../../Services/userApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import ConfirmationModal from '../Modals/ConfirmationModal';
import MatTable from '../Tables/MatTable';
import AddEditAddress from '../Modals/User/AddEditAddress';
import { Link } from 'react-router-dom';
import AddEditKapanDetails from '../Modals/Stones/AddEditKapanDetails';

const limit = DATA_LIMIT - 5;

const column = [
  {
    label: 'Kapan Id',
    id: 'kapanId',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
      <Link
        className="textDecoration-none link-text"
        to={`stoneDetails/${row?._id}`}
      >
        <Typography
          variant="span"
          style={{ whiteSpace: 'nowrap' }}
          // className={'link-text'}
          // onClick={() => {
          //   customHistory.push(`order/orderDetails/${columnData}`);
          // }}
        >
          {columnData?.kapanId}
        </Typography>
      </Link>
    ),
  },
  {
    label: 'Employee Name',
    id: 'employeeId',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData?.name || '-'}
      </span>
    ),
  },
  {
    label: 'Carat',
    id: 'plannigCarat',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || '-'}
      </span>
    ),
  },
];

function GelexyData({
  addressListData,
  wishlistValue,
  userId,
  getListing,
  rightSection,
  handleMoveToCart,
}) {
  const [tableListing, setTableListing] = useState([]);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [openAddEditAddress, setOpenAddEditAddress] = useState(false);
  const [selectedAddressData, setSelectedAddressData] = useState();
  const [deleteData, setDeleteData] = useState({});

  useEffect(() => {
    if (wishlistValue?.length >= 0) {
      let data = wishlistValue?.map((item) => {
        return {
          image_url:
            `${ApiConfig.BASE_IMAGE_URL}${item?.shoot_id}/${item?.rank}-${item?.Name}.jpg` ||
            '',
          image_id: item?.image_id,
          wishlist_id: item?.wishlist_id,
          image_name: item?.Name || '',
          shootid: item?.shoot_id || '',
          rank: item?.rank || '',
          sno: item?.sno || '',
          value: item?.Name || '',
          label: item?.Name || '',
          quality: null,
          type: item?.type == 2 ? 'video' : 'image',
        };
      });
      setTableListing(data);
    }
  }, [wishlistValue]);

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton
            onClick={async () => {
              handleCofirmModalOpen(true);
              setDeleteData(data?._id);
            }}
            disabled={true}
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              bgcolor: 'buttonDanger.main',
              color: 'buttonSecondary.contrastText',
            }}
          >
            Delete
          </CustomButton>
        </div>
      </>
    );
  };
  const handleAdd = (value) => {
    setOpenAddEditAddress(value);
  };
  const handleDelete = async (value) => {
    try {
      if (value && deleteData) {
        const resp = await deleteAddress(deleteData);
        if (resp) {
          getListing();
        }
      } else {
        setDeleteData(null);
      }
    } catch (e) {}
  };

  const handleCofirmModalOpen = (value) => {
    setConfirmDelete(value);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: (theme) => theme.spacing(3),
          py: (theme) => theme.spacing(2),
        }}
      >
        <Typography variant="h3" sx={{}}>
          Gelexy Data
        </Typography>
        {rightSection ? rightSection() : null}
      </Box>
      <MatTable
        showCheckbox={false}
        columns={column}
        data={addressListData}
        // requestSearch={requestSearch}
        // cancelSearch={cancelSearch}
        // onApplyFilter={(data={})=>{
        //   getListing({
        //     page: String(1),
        //     start_date: "",
        //     end_date: "",
        //     ...getObjectSubset(data, 'start_date', 'end_date'),
        //   });
        //   if(Object.keys(data).length > 0){
        //     setAllParams({
        //       ...allParams,
        //       page: String(1),
        //       ...getObjectSubset(data, 'start_date', 'end_date'),
        //     });
        //   }else{
        //     setAllParams({
        //       page: String(1),
        //     });
        //   }
        // }}
        // showFilterButton={true}
        // showSerialNo={false}
        action={actionBody}
        // actionHead={actionHead}
        page={page}
        // changeFilter={changeFilter}
        // handleFilterPopup={toggleFilters}
        perPage={limit}
        // setFilters={setFilters}
        // filters={filters}
        total={addressListData?.length || 0}
        // defaultSort="transactionTime"
        // defaultSortOrder={isMobile ? sortOrder : 'desc'}
        // searchTerm={searchTerm}
        setPage={setPage}
        // handleSearch={(searchTerm)=>{
        //   getListing({
        //     page: String(1),
        //     search: searchTerm
        //   })
        //   setAllParams({
        //     ...allParams,
        //     page: String(1),
        //     search: searchTerm
        //   })
        // }}
        handlePageChange={(newPage) => {
          getListing(
            {
              page: String(newPage),
            },
            setPage,
          );
        }}
        // toolbarEnable={true}
        pagination={false}
        // filterEnable
        // rangeDateFilter
      />
      {confirmDelete && (
        <ConfirmationModal
          open={confirmDelete}
          handleConfirm={handleDelete}
          setOpen={handleCofirmModalOpen}
        />
      )}
      {openAddEditAddress && (
        <AddEditKapanDetails
          open={openAddEditAddress}
          handleOpen={handleAdd}
          editMode={true}
          getListing={getListing}
          selectedData={selectedAddressData}
        />
      )}
    </>
  );
}

export default GelexyData;
