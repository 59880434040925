import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { errorMessage } from '../lib/helper';
import { STATUS_200, STATUS_300 } from '../data/constants';
import {
  setAllProductListing,
  setAllProductListingLoading,
  setAllProductListingError,
  setSingleProductListing,
  setSingleProductListingLoading,
  setSingleProductListingError,
} from '../redux/product';

export const getAllProductListing = (payload, isSingleProduct) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(
          isSingleProduct
            ? setSingleProductListingLoading(true)
            : setAllProductListingLoading(true),
        );
        const resp = await axios.post(`${ApiConfig.productList}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(
            isSingleProduct
              ? setSingleProductListing(resp.data.data)
              : setAllProductListing(resp.data.data),
          );
          dispatch(
            isSingleProduct
              ? setSingleProductListingLoading(false)
              : setAllProductListingLoading(false),
          );
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          isSingleProduct
            ? setSingleProductListingError({
                isError: true,
                message: errorMessage(e),
              })
            : setAllProductListingError({
                isError: true,
                message: errorMessage(e),
              }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(
          isSingleProduct
            ? setSingleProductListingLoading(false)
            : setAllProductListingLoading(false),
        );
      }
    };
  }
};

export const getProductsBySearch = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.productList}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp?.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const createProduct = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.adminCreateProduct}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const updateProduct = async (payload, userId) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.put(
        `${ApiConfig.adminUpdateProduct}/${userId}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
    }
  }
};

export const deleteProduct = async (productId) => {
  if (productId !== undefined) {
    try {
      const resp = await axios.delete(
        `${ApiConfig.adminDeleteProduct}/${productId}`,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};
