import React, { useState } from 'react';
import * as Yup from 'yup';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import CustomInput from '../../CommonComp/CustomInput/CustomInput';
import CustomButton from '../../CommonComp/Buttons/CustomButton';
import CustomCheckbox from '../../CommonComp/CustomInput/CheckboxField';
import { formatDate } from '../../../lib/helper';
import CustomPhoneInput from '../../CommonComp/CustomInput/CustomPhoneInput';
import {
  CreateAddress,
  addRemoveWishlist,
  addToCartUser,
  updateAddress,
} from '../../../Services/userApis';
import CustomAsyncSelect from '../../CommonComp/CustomInput/CustomAsyncSelect';
import CustomIconButtons from '../../CommonComp/Buttons/IconButtons';
import { WishlistIcon } from '../../../Asset/svg';
import {
  getAllProductListing,
  getProductsBySearch,
} from '../../../Services/productApis';
import { useParams } from 'react-router-dom';

function AddEditCartList({
  open,
  handleOpen,
  userData,
  getListing,
  selectedData,
  editMode,
  userId,
}) {
  const [loading, setLoading] = useState(false);
  const [imagesData, setImagesData] = useState([]);
  const [selectedSize, setSelectedSize] = useState();
  const [quantity, setQuantity] = useState(1);
  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    let resp = editMode
      ? await updateAddress(values, selectedData?._id)
      : await CreateAddress(values);
    if (resp) {
      resetForm({
        userId: '',
        email: '',
        name: '',
        mobileNo: '',
        type: '',
        pinCode: '',
        city: '',
        state: '',
        addressLine1: '',
        addressLine2: '',
        landmark: '',
        isDefault: false,
      });
      getListing();
      handleOpen(false);
    }
    setLoading(false);
  };
  const handleDelete = (index) => {
    let updatedData = [...imagesData];
    updatedData.splice(index, 1);
    setImagesData(updatedData);
  };
  const getProductListing = async (inputValue) => {
    if (inputValue && inputValue.length > 1) {
      const payload = {
        page: String(1),
        limit: 10,
        search: inputValue,
      };
      let data = await getProductsBySearch(payload);
      let updatedData = data?.data?.results?.map((item, index) => {
        return {
          value: item?._id,
          label: item?.sku,
          title: item?.title,
          url: item?.url,
          size: item?.size,
          imagesData: item?.imagesData[0]?.desktopImageURL,
          selectedSize: item?.size[0]?.name,
          selectedQuantity: 1,
        };
      });
      return updatedData || [];
    }
  };

  return (
    <div>
      <Dialog
        maxWidth={'lg'}
        fullWidth
        open={open}
        onClose={() => {
          handleOpen(false);
        }}
      >
        <DialogTitle variant="h3">
          {editMode ? 'Edit Wishlist' : 'Add Product To Cart'}
        </DialogTitle>
        <DialogContent>
          <Box noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <CustomAsyncSelect
                  promiseOptions={getProductListing}
                  // error={error}
                  // helperText={helperText}
                  closeMenuOnSelect={false}
                  controlStyle={{
                    padding: 5,
                  }}
                  cacheOptions={true}
                  value={imagesData}
                  handleChange={(e) => {
                    setImagesData(e);
                  }}
                  width={'100%'}
                  dropDownZIndex={15000}
                  labelShrink={true}
                  label="Search"
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  className="border-1 rounded-3"
                  mt={5}
                  px={2}
                  py={1}
                  minHeight={150}
                >
                  <Typography variant="h6">Preview</Typography>
                  {/* {imagesData?.length ?  */}
                  {imagesData?.map((data, index) => {
                    return (
                      <Box
                        sx={{
                          display: 'flex',
                          overflow: 'hidden',
                          overflowX: 'auto',
                          padding: '1rem',
                          gap: '2rem',
                          alignItems: 'center',
                        }}
                        key={index}
                      >
                        <img
                          src={data?.imagesData}
                          alt="Image"
                          width={150}
                          height={'auto'}
                          style={{ borderRadius: '1rem' }}
                        />
                        <Typography
                          variant="span"
                          style={{ whiteSpace: 'nowrap' }}
                          // className={'link-text'}
                          // onClick={() => {
                          //   customHistory.push(`order/orderDetails/${columnData}`);
                          // }}
                        >
                          {data?.label}
                        </Typography>
                        <CustomInput
                          name="type"
                          label="Select Size"
                          fullWidth
                          placeholder="Select Size"
                          value={data?.selectedSize}
                          InputLabelProps={{ shrink: true }}
                          select
                          SelectProps={{
                            native: true,
                          }}
                          InputProps={{
                            classes: {
                              notchedOutline: 'rounded',
                            },
                          }}
                          onChange={(e) => {
                            const dataBase = [...imagesData];
                            dataBase[index].selectedSize = e.target.value;
                            setImagesData(dataBase);
                          }}
                        >
                          <option key="Select Size" value="">
                            Select Size
                          </option>
                          {data?.size?.map((subData, index) => {
                            return (
                              <option key={subData?.name} value={subData?.name}>
                                {subData?.name}
                              </option>
                            );
                          })}
                        </CustomInput>
                        <CustomInput
                          variant="outlined"
                          fullWidth
                          name="quantity"
                          label="Quantity"
                          type="number"
                          placeholder="Quantity"
                          value={data?.selectedQuantity}
                          onChange={(e) => {
                            const dataBase = [...imagesData];
                            dataBase[index].selectedQuantity = e.target.value;
                            setImagesData(dataBase);
                          }}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            classes: {
                              notchedOutline: 'rounded',
                            },
                            inputProps: { min: 1, max: 10 },
                          }}
                        />
                        <CustomButton
                          onClick={async () => {
                            const payload = {
                              userId: userId,
                              quantity: data?.selectedQuantity,
                              size: data?.selectedSize,
                            };
                            await addToCartUser(payload, data?.value);
                            getListing();
                          }}
                          fullWidth
                          sx={{
                            padding: '0.5rem 10px',
                            marginRight: 1,
                            fontSize: '14px',
                            whiteSpace: 'nowrap',
                            bgcolor: 'buttonSecondary.main',
                            color: 'buttonSecondary.contrastText',
                          }}
                        >
                          Add To Cart
                        </CustomButton>
                      </Box>
                    );
                  })}
                  {/* :  */}

                  {/* <Typography
                    variant="p"
                    component={'p'}
                    className="fontWeight500"
                    textAlign={'center'}
                    pt={4}
                  >
                    No Data Available
                  </Typography> */}
                  {/* } */}
                </Box>
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              {/* <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Confirm
              </CustomButton> */}
              <CustomButton
                onClick={() => {
                  handleOpen(false);
                }}
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddEditCartList;
