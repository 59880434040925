import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  allCouponsList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  singleCouponList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
};

export const couponSlice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAllCouponListing: (state, action) => {
      state.allCouponsList.items = action.payload;
      state.allCouponsList.error = {
        isError: false,
        message: '',
      };
    },
    setAllCouponListingLoading: (state, action) => {
      state.allCouponsList.loading = action.payload;
    },
    setAllCouponListingError: (state, action) => {
      state.allCouponsList.items = {};
      state.allCouponsList.error = action.payload;
    },
    setSingleCouponListing: (state, action) => {
      state.singleCouponList.items = action.payload;
      state.singleCouponList.error = {
        isError: false,
        message: '',
      };
    },
    setSingleCouponListingLoading: (state, action) => {
      state.singleCouponList.loading = action.payload;
    },
    setSingleCouponListingError: (state, action) => {
      state.singleCouponList.items = {};
      state.singleCouponList.error = action.payload;
    },
  },
});

export default couponSlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setAllCouponListing,
  setAllCouponListingLoading,
  setAllCouponListingError,
  setSingleCouponListing,
  setSingleCouponListingLoading,
  setSingleCouponListingError,
} = couponSlice.actions;

export const couponItemsSelector = (state) => state.coupon;
