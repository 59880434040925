export const UserData = {
  tabs: [
    {
      label: 'All',
      id: 1,
    },
  ],
};

export const AdminUserData = {
  tabs: [
    {
      label: 'All Admins',
      id: 1,
    },
  ],
};
