import { Box, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import { userItemsSelector } from '../../redux/user';
import CustomPhoneInput from '../CommonComp/CustomInput/CustomPhoneInput';
import { DATA_LIMIT } from '../../data/constants';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomCheckbox from '../CommonComp/CustomInput/CheckboxField';
import {
  getAllAddressListing,
  getAllUserListing,
  updateUser,
} from '../../Services/userApis';
import { formatDate } from '../../lib/helper';
import { toast } from 'react-toastify';
import Divder from '../CommonComp/Divder';
import {
  createProduct,
  getAllProductListing,
  updateProduct,
} from '../../Services/productApis';
import { productItemsSelector } from '../../redux/product';
import { getAllCategoryListing } from '../../Services/categoryApis';
import { categoryItemsSelector } from '../../redux/category';
import MatTable from '../Tables/MatTable';

const limit = DATA_LIMIT;

function CmsDetailPage({ productId, isEdit }) {
  const { addressList, singleUserList } = useSelector(userItemsSelector);
  const { allProductList, singleProductList } =
    useSelector(productItemsSelector);
  const dispatch = useDispatch();
  const { subCategoryList, allCategoryList } = useSelector(
    categoryItemsSelector,
  );
  const [openAddEditAddress, setOpenAddEditAddress] = useState(false);
  const [mainDataOfProduct, setMainDataOfProfuct] = useState({});
  const handleConfirm = async (values, { resetForm }) => {
    let tempValues = { ...values };
    let masterPayload = {
      ...tempValues,
      productInformation: {
        productDetails: tempValues?.productDetails,
        otherDetails: tempValues?.otherDetails,
        returnPolicy: tempValues?.returnPolicy,
      },
    };
    delete masterPayload?.productDetails;
    delete masterPayload?.otherDetails;
    delete masterPayload?.returnPolicy;
    if (isEdit) {
      let resp = await updateProduct(masterPayload, productId);
    } else {
      let resp = await createProduct(masterPayload);
    }
  };
  const getListing = async (params = {}) => {
    const payload = {
      type: 'main',
    };
    dispatch(getAllCategoryListing(payload));
  };
  useEffect(() => {
    getListing();
  }, []);
  const getProductDetail = async () => {
    const payload = {
      page: String(1),
      limit: limit,
      productId: isEdit ? productId : '',
    };
    if (isEdit) {
      dispatch(getAllProductListing(payload, true));
    }
  };
  const handleChangeMobile = (val) => {
    formik.setFieldValue('mobile', val);
  };
  useEffect(() => {
    if (isEdit) {
      getProductDetail();
    }
  }, []);
  useEffect(() => {
    if (
      singleProductList?.items &&
      Object.keys(singleProductList?.items)?.length > 0
    ) {
      setMainDataOfProfuct(singleProductList?.items);
      // setMainDataOfProfuct({});
    }
  }, [singleProductList?.items]);
  const formik = useFormik({
    initialValues: {
      sku: isEdit ? mainDataOfProduct?.sku || '' : '',
      title: isEdit ? mainDataOfProduct?.title || '' : '',
      subTitle: isEdit ? mainDataOfProduct?.subTitle || '' : '',
      basePrice: isEdit ? mainDataOfProduct?.basePrice || '' : '',
      offerPrice: isEdit ? mainDataOfProduct?.offerPrice || '' : '',
      discount: isEdit ? mainDataOfProduct?.discount || '' : '',
      url: isEdit ? mainDataOfProduct?.url || '' : '',
      mainCategory: isEdit ? mainDataOfProduct?.mainCategory || '' : '',
      subCategory: isEdit ? mainDataOfProduct?.subCategory || '' : '',
      isActive: isEdit
        ? Boolean(mainDataOfProduct?.isActive) || Boolean(false)
        : Boolean(false),
      imagesData: isEdit ? mainDataOfProduct?.imagesData || [] : [],
      size: isEdit ? mainDataOfProduct?.size || [] : [],
      productDetails: isEdit
        ? mainDataOfProduct?.productInformation?.productDetails || []
        : [],
      otherDetails: isEdit
        ? mainDataOfProduct?.productInformation?.otherDetails || []
        : [],
      returnPolicy: isEdit
        ? mainDataOfProduct?.productInformation?.returnPolicy || []
        : [],
    },
    validationSchema: Yup.object({
      sku: Yup.string().required('Sku is required.'),
      title: Yup.string().required('Title is required.'),
      subTitle: Yup.string().required('Sub Title is required.'),
      basePrice: Yup.string().required('Base Price is required.'),
      offerPrice: Yup.string().required('Offer Price is required.'),
      discount: Yup.string().required('Discount is required.'),
      url: Yup.string().required('URL is required.'),
      mainCategory: Yup.string().required('Main Category is required.'),
      subCategory: Yup.string().required('Sub Category is required.'),
      isActive: Yup.string().required('Is Active is required.'),
      imagesData: Yup.array().of(
        Yup.object().shape({
          desktopImageURL: Yup.string().required('Desktop Image is required'),
          mobileImageURL: Yup.string().required('Mobile Image is required'),
        }),
      ),
      size: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('Name is required'),
        }),
      ),
      productDetails: Yup.array().of(
        Yup.object().shape({
          title: Yup.string().required('Title is required'),
          value: Yup.string().required('Value is required'),
        }),
      ),
      otherDetails: Yup.array().of(
        Yup.object().shape({
          title: Yup.string().required('Title is required'),
          value: Yup.string().required('Value is required'),
        }),
      ),
      returnPolicy: Yup.array().of(
        Yup.object().shape({
          title: Yup.string().required('Title is required'),
          value: Yup.string().required('Value is required'),
        }),
      ),
    }),
    enableReinitialize: true,
    onSubmit: handleConfirm,
  });

  useEffect(() => {
    if (mainDataOfProduct?.mainCategory) {
      const payloadForSubCategory = {
        getSubCate: mainDataOfProduct?.mainCategory,
      };
      dispatch(getAllCategoryListing(payloadForSubCategory, true));
    }
  }, [mainDataOfProduct?.mainCategory]);

  const handleAddNewImage = (value) => {
    let tempArray = [...formik.values?.imagesData];
    let emptyObj = {
      desktopImageURL: '',
      mobileImageURL: '',
      isMain: 'false',
    };
    tempArray?.push(emptyObj);
    formik.setValues({ ...formik.values, imagesData: tempArray });
  };

  const handleAddNewSize = (value) => {
    let tempArray = [...formik.values?.size];
    let emptyObj = {
      name: '',
    };
    tempArray?.push(emptyObj);
    formik.setValues({ ...formik.values, size: tempArray });
  };
  const handleAddNewProductDetail = (value) => {
    let tempArray = [...formik.values?.productDetails];
    let emptyObj = {
      title: '',
      value: '',
    };
    tempArray?.push(emptyObj);
    formik.setValues({ ...formik.values, productDetails: tempArray });
  };
  const handleAddNewOtherDetail = (value) => {
    let tempArray = [...formik.values?.otherDetails];
    let emptyObj = {
      title: '',
      value: '',
    };
    tempArray?.push(emptyObj);
    formik.setValues({ ...formik.values, otherDetails: tempArray });
  };
  const handleAddNewReturnPolicy = (value) => {
    let tempArray = [...formik.values?.returnPolicy];
    let emptyObj = {
      title: '',
      value: '',
    };
    tempArray?.push(emptyObj);
    formik.setValues({ ...formik.values, returnPolicy: tempArray });
  };

  const handleRemoveNewImage = (data, rowIndex) => {
    let tempArray = [...formik?.values?.imagesData];
    tempArray.splice(rowIndex, 1);
    formik.setValues({ ...formik?.values, imagesData: tempArray });
  };

  const handleRemoveNewSize = (data, rowIndex) => {
    let tempArray = [...formik?.values?.size];
    tempArray.splice(rowIndex, 1);
    formik.setValues({ ...formik?.values, size: tempArray });
  };
  const handleRemoveNewProductDetail = (data, rowIndex) => {
    let tempArray = [...formik?.values?.productDetails];
    tempArray.splice(rowIndex, 1);
    formik.setValues({ ...formik?.values, productDetails: tempArray });
  };
  const handleRemoveNewOtherDetail = (data, rowIndex) => {
    let tempArray = [...formik?.values?.otherDetails];
    tempArray.splice(rowIndex, 1);
    formik.setValues({ ...formik?.values, otherDetails: tempArray });
  };
  const handleRemoveNewReturnPolicy = (data, rowIndex) => {
    let tempArray = [...formik?.values?.returnPolicy];
    tempArray.splice(rowIndex, 1);
    formik.setValues({ ...formik?.values, returnPolicy: tempArray });
  };

  const columnForImage = [
    {
      label: 'Desktop Image',
      id: 'desktopImageURL',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={columnData}
            alt="Image Product"
            style={{ maxWidth: 100, maxHeight: 140, borderRadius: '5px' }}
          />
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 300 }}
            label="Desktop Image"
            placeholder="Enter Desktop Image"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            id={`imagesData[${rowIndex}].desktopImageURL`}
            name={`imagesData[${rowIndex}].desktopImageURL`}
            value={columnData}
            onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            error={
              formik.touched.imagesData &&
              formik.touched.imagesData[rowIndex]?.desktopImageURL &&
              formik.errors.imagesData &&
              formik.errors.imagesData[rowIndex]?.desktopImageURL
                ? true
                : false
            }
            helperText={
              formik.touched.imagesData &&
              formik.touched.imagesData[rowIndex]?.desktopImageURL &&
              formik.errors.imagesData &&
              formik.errors.imagesData[rowIndex]?.desktopImageURL
            }
          />
        </span>
      ),
    },
    {
      label: 'Mobile Image',
      id: 'mobileImageURL',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={columnData}
            alt="Image Product"
            style={{ maxWidth: 100, maxHeight: 140, borderRadius: '5px' }}
          />
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 300 }}
            label="Mobile Image"
            placeholder="Enter Mobile Image"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            id={`imagesData[${rowIndex}].mobileImageURL`}
            name={`imagesData[${rowIndex}].mobileImageURL`}
            value={columnData}
            onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            error={
              formik.touched.imagesData &&
              formik.touched.imagesData[rowIndex]?.mobileImageURL &&
              formik.errors.imagesData &&
              formik.errors.imagesData[rowIndex]?.mobileImageURL
                ? true
                : false
            }
            helperText={
              formik.touched.imagesData &&
              formik.touched.imagesData[rowIndex]?.mobileImageURL &&
              formik.errors.imagesData &&
              formik.errors.imagesData[rowIndex]?.mobileImageURL
            }
          />
        </span>
      ),
    },
    {
      label: 'Is Main',
      id: 'isMain',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
          <CustomInput
            InputLabelProps={{ shrink: true }}
            select
            SelectProps={{
              native: true,
            }}
            variant="outlined"
            sx={{ minWidth: 300 }}
            label="Is Main Image"
            placeholder="Select True If Yes"
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            id={`imagesData[${rowIndex}].isMain`}
            name={`imagesData[${rowIndex}].isMain`}
            value={columnData}
            onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            error={
              formik.touched.imagesData &&
              formik.touched.imagesData[rowIndex]?.isMain &&
              formik.errors.imagesData &&
              formik.errors.imagesData[rowIndex]?.isMain
                ? true
                : false
            }
            helperText={
              formik.touched.imagesData &&
              formik.touched.imagesData[rowIndex]?.isMain &&
              formik.errors.imagesData &&
              formik.errors.imagesData[rowIndex]?.isMain
            }
          >
            <option key="true" value="true">
              True
            </option>
            <option key="false" value="false">
              False
            </option>
          </CustomInput>
        </span>
      ),
    },
  ];

  const columnForSize = [
    {
      label: 'Name',
      id: 'name',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 300 }}
            label="Size"
            placeholder="Enter Size"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            id={`size[${rowIndex}].name`}
            name={`size[${rowIndex}].name`}
            value={columnData}
            onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            error={
              formik.touched.size &&
              formik.touched.size[rowIndex]?.name &&
              formik.errors.size &&
              formik.errors.size[rowIndex]?.name
                ? true
                : false
            }
            helperText={
              formik.touched.size &&
              formik.touched.size[rowIndex]?.name &&
              formik.errors.size &&
              formik.errors.size[rowIndex]?.name
            }
          />
        </span>
      ),
    },
  ];
  const columnForProductDetail = [
    {
      label: 'Title',
      id: 'title',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 300 }}
            label="Title"
            placeholder="Enter Title"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            id={`productDetails[${rowIndex}].title`}
            name={`productDetails[${rowIndex}].title`}
            value={columnData}
            onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            error={
              formik.touched.productDetails &&
              formik.touched.productDetails[rowIndex]?.title &&
              formik.errors.productDetails &&
              formik.errors.productDetails[rowIndex]?.title
                ? true
                : false
            }
            helperText={
              formik.touched.productDetails &&
              formik.touched.productDetails[rowIndex]?.title &&
              formik.errors.productDetails &&
              formik.errors.productDetails[rowIndex]?.title
            }
          />
        </span>
      ),
    },
    {
      label: 'Value',
      id: 'value',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 300 }}
            label="Value"
            placeholder="Enter Value"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            id={`productDetails[${rowIndex}].value`}
            name={`productDetails[${rowIndex}].value`}
            value={columnData}
            onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            error={
              formik.touched.productDetails &&
              formik.touched.productDetails[rowIndex]?.value &&
              formik.errors.productDetails &&
              formik.errors.productDetails[rowIndex]?.value
                ? true
                : false
            }
            helperText={
              formik.touched.productDetails &&
              formik.touched.productDetails[rowIndex]?.value &&
              formik.errors.productDetails &&
              formik.errors.productDetails[rowIndex]?.value
            }
          />
        </span>
      ),
    },
  ];
  const columnForOtherDetail = [
    {
      label: 'Title',
      id: 'title',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 300 }}
            label="Title"
            placeholder="Enter Title"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            id={`otherDetails[${rowIndex}].title`}
            name={`otherDetails[${rowIndex}].title`}
            value={columnData}
            onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            error={
              formik.touched.otherDetails &&
              formik.touched.otherDetails[rowIndex]?.title &&
              formik.errors.otherDetails &&
              formik.errors.otherDetails[rowIndex]?.title
                ? true
                : false
            }
            helperText={
              formik.touched.otherDetails &&
              formik.touched.otherDetails[rowIndex]?.title &&
              formik.errors.otherDetails &&
              formik.errors.otherDetails[rowIndex]?.title
            }
          />
        </span>
      ),
    },
    {
      label: 'Value',
      id: 'value',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 300 }}
            label="Value"
            placeholder="Enter Value"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            id={`otherDetails[${rowIndex}].value`}
            name={`otherDetails[${rowIndex}].value`}
            value={columnData}
            onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            error={
              formik.touched.otherDetails &&
              formik.touched.otherDetails[rowIndex]?.value &&
              formik.errors.otherDetails &&
              formik.errors.otherDetails[rowIndex]?.value
                ? true
                : false
            }
            helperText={
              formik.touched.otherDetails &&
              formik.touched.otherDetails[rowIndex]?.value &&
              formik.errors.otherDetails &&
              formik.errors.otherDetails[rowIndex]?.value
            }
          />
        </span>
      ),
    },
  ];
  const columnForReturnPolicy = [
    {
      label: 'Title',
      id: 'title',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 300 }}
            label="Title"
            placeholder="Enter Title"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            id={`returnPolicy[${rowIndex}].title`}
            name={`returnPolicy[${rowIndex}].title`}
            value={columnData}
            onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            error={
              formik.touched.returnPolicy &&
              formik.touched.returnPolicy[rowIndex]?.title &&
              formik.errors.returnPolicy &&
              formik.errors.returnPolicy[rowIndex]?.title
                ? true
                : false
            }
            helperText={
              formik.touched.returnPolicy &&
              formik.touched.returnPolicy[rowIndex]?.title &&
              formik.errors.returnPolicy &&
              formik.errors.returnPolicy[rowIndex]?.title
            }
          />
        </span>
      ),
    },
    {
      label: 'Value',
      id: 'value',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 300 }}
            label="Value"
            placeholder="Enter Value"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            id={`returnPolicy[${rowIndex}].value`}
            name={`returnPolicy[${rowIndex}].value`}
            value={columnData}
            onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            error={
              formik.touched.returnPolicy &&
              formik.touched.returnPolicy[rowIndex]?.value &&
              formik.errors.returnPolicy &&
              formik.errors.returnPolicy[rowIndex]?.value
                ? true
                : false
            }
            helperText={
              formik.touched.returnPolicy &&
              formik.touched.returnPolicy[rowIndex]?.value &&
              formik.errors.returnPolicy &&
              formik.errors.returnPolicy[rowIndex]?.value
            }
          />
        </span>
      ),
    },
  ];
  const renderAddNewAddress = () => {
    return (
      <CustomButton
        className="buttonDense"
        onClick={() => handleAddNewImage(true)}
        color={'buttonPrimary'}
      >
        Add New Address
      </CustomButton>
    );
  };
  const handleMoveToCart = async (item, index, length) => {
    // let data = [item]
    // setInitialData(data)
    // handleAddNewImageToModalOpen(true, 0)
  };
  const actionBody = (data, rowIndex) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton
            onClick={() => handleRemoveNewImage(data, rowIndex)}
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              bgcolor: 'buttonDanger.main',
              color: 'buttonSecondary.contrastText',
            }}
          >
            Delete
          </CustomButton>
        </div>
      </>
    );
  };

  const actionBodyForSizes = (data, rowIndex) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton
            onClick={() => handleRemoveNewSize(data, rowIndex)}
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              bgcolor: 'buttonDanger.main',
              color: 'buttonSecondary.contrastText',
            }}
          >
            Delete
          </CustomButton>
        </div>
      </>
    );
  };
  const actionBodyForProductDetails = (data, rowIndex) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton
            onClick={() => handleRemoveNewProductDetail(data, rowIndex)}
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              bgcolor: 'buttonDanger.main',
              color: 'buttonSecondary.contrastText',
            }}
          >
            Delete
          </CustomButton>
        </div>
      </>
    );
  };
  const actionBodyForOtherDetails = (data, rowIndex) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton
            onClick={() => handleRemoveNewOtherDetail(data, rowIndex)}
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              bgcolor: 'buttonDanger.main',
              color: 'buttonSecondary.contrastText',
            }}
          >
            Delete
          </CustomButton>
        </div>
      </>
    );
  };
  const actionBodyForReturnPolicy = (data, rowIndex) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton
            onClick={() => handleRemoveNewReturnPolicy(data, rowIndex)}
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              bgcolor: 'buttonDanger.main',
              color: 'buttonSecondary.contrastText',
            }}
          >
            Delete
          </CustomButton>
        </div>
      </>
    );
  };
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <CustomBox padding={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <CustomInput
                label="SKU"
                fullWidth
                placeholder="Enter SKU"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={formik.handleChange}
                name="sku"
                value={formik.values.sku}
                error={formik.touched.sku && Boolean(formik.errors.sku)}
                helperText={formik.touched.sku && formik.errors.sku}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomInput
                label="Title"
                fullWidth
                placeholder="Enter Title"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={formik.handleChange}
                name="title"
                value={formik.values.title}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomInput
                label="Sub Title"
                fullWidth
                multiline
                rows={3}
                placeholder="Enter Sub Title"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={formik.handleChange}
                name="subTitle"
                value={formik.values.subTitle}
                error={
                  formik.touched.subTitle && Boolean(formik.errors.subTitle)
                }
                helperText={formik.touched.subTitle && formik.errors.subTitle}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomInput
                label="MRP"
                fullWidth
                placeholder="Enter MRP"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={formik.handleChange}
                name="basePrice"
                value={formik.values.basePrice}
                error={
                  formik.touched.basePrice && Boolean(formik.errors.basePrice)
                }
                helperText={formik.touched.basePrice && formik.errors.basePrice}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomInput
                label="Offer Price"
                fullWidth
                placeholder="Enter Offer Price"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={formik.handleChange}
                name="offerPrice"
                value={formik.values.offerPrice}
                error={
                  formik.touched.offerPrice && Boolean(formik.errors.offerPrice)
                }
                helperText={
                  formik.touched.offerPrice && formik.errors.offerPrice
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomInput
                label="Discount Percentage"
                fullWidth
                placeholder="Enter Discount Percentage"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={formik.handleChange}
                name="discount"
                value={formik.values.discount}
                error={
                  formik.touched.discount && Boolean(formik.errors.discount)
                }
                helperText={formik.touched.discount && formik.errors.discount}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInput
                label="Product URL"
                fullWidth
                placeholder="Enter Product URL"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={formik.handleChange}
                name="url"
                value={formik.values.url}
                error={formik.touched.url && Boolean(formik.errors.url)}
                helperText={formik.touched.url && formik.errors.url}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomInput
                label="Main Category"
                fullWidth
                placeholder="Select Main Category"
                InputLabelProps={{ shrink: true }}
                select
                SelectProps={{
                  native: true,
                }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={(e) => {
                  formik.setFieldValue('mainCategory', e.target.value);
                  const payloadForSubCategory = {
                    getSubCate: e.target.value,
                  };
                  dispatch(getAllCategoryListing(payloadForSubCategory, true));
                }}
                value={formik.values.mainCategory}
                name="mainCategory"
                error={
                  formik.touched.mainCategory &&
                  Boolean(formik.errors.mainCategory)
                }
                helperText={
                  formik.touched.mainCategory && formik.errors.mainCategory
                }
              >
                <option key="Select Main Category" value="">
                  Select Main Category
                </option>
                {allCategoryList?.items?.results?.map((data, index) => {
                  return (
                    <option key={data?._id} value={data?._id}>
                      {data?.name}
                    </option>
                  );
                })}
              </CustomInput>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomInput
                label="Sub Category"
                fullWidth
                placeholder="Sub Category"
                InputLabelProps={{ shrink: true }}
                select
                SelectProps={{
                  native: true,
                }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={formik.handleChange}
                value={formik.values.subCategory}
                name="subCategory"
                error={
                  formik.touched.subCategory &&
                  Boolean(formik.errors.subCategory)
                }
                helperText={
                  formik.touched.subCategory && formik.errors.subCategory
                }
              >
                <option key="Select Sub Category" value="">
                  Select Sub Category
                </option>
                {subCategoryList?.items?.results?.map((data, index) => {
                  return (
                    <option key={index} value={data?._id}>
                      {data?.name}
                    </option>
                  );
                })}
              </CustomInput>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomCheckbox
                label={'Is Active'}
                labelPlacement={'end'}
                subLableText={'Checked'}
                handleChange={(e) => {
                  formik.setFieldValue(
                    'isActive',
                    e.target.checked == true ? true : false,
                  );
                }}
                name="isActive"
                value={Boolean(formik.values.isActive)}
                error={
                  formik.touched.isActive && Boolean(formik.errors.isActive)
                }
                helperText={formik.touched.isActive && formik.errors.isActive}
              />
            </Grid>
          </Grid>
        </CustomBox>
        <Divder spacing={1} />
        <CustomBox padding={0}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: (theme) => theme.spacing(3),
              py: (theme) => theme.spacing(2),
            }}
          >
            <Typography variant="h3" sx={{}}>
              Images
            </Typography>
            <CustomButton
              className="buttonDense"
              onClick={() => handleAddNewImage(formik.values)}
              color={'buttonPrimary'}
            >
              Add New Image
            </CustomButton>
          </Box>
          <Divder spacing={1} />
          <MatTable
            showCheckbox={false}
            columns={columnForImage}
            data={formik.values.imagesData}
            action={actionBody}
            total={0}
            handlePageChange={(newPage) => {
              getListing(
                {
                  page: String(newPage),
                },
                setPage,
              );
            }}
            pagination={false}
          />
        </CustomBox>
        <Divder spacing={1} />
        <CustomBox padding={0}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: (theme) => theme.spacing(3),
              py: (theme) => theme.spacing(2),
            }}
          >
            <Typography variant="h3" sx={{}}>
              Sizes
            </Typography>
            <CustomButton
              className="buttonDense"
              onClick={() => handleAddNewSize(true)}
              color={'buttonPrimary'}
            >
              Add New Size
            </CustomButton>
          </Box>
          <Divder spacing={1} />
          <MatTable
            showCheckbox={false}
            columns={columnForSize}
            data={formik.values.size}
            action={actionBodyForSizes}
            total={0}
            handlePageChange={(newPage) => {
              getListing(
                {
                  page: String(newPage),
                },
                setPage,
              );
            }}
            pagination={false}
          />
        </CustomBox>
        <Divder spacing={1} />
        <CustomBox padding={0}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: (theme) => theme.spacing(3),
              py: (theme) => theme.spacing(2),
            }}
          >
            <Typography variant="h3" sx={{}}>
              Product Details
            </Typography>
            <CustomButton
              className="buttonDense"
              onClick={() => handleAddNewProductDetail(true)}
              color={'buttonPrimary'}
            >
              Add Product Details
            </CustomButton>
          </Box>
          <Divder spacing={1} />
          <MatTable
            showCheckbox={false}
            columns={columnForProductDetail}
            data={formik.values.productDetails}
            action={actionBodyForProductDetails}
            total={0}
            pagination={false}
          />
        </CustomBox>
        <Divder spacing={1} />
        <CustomBox padding={0}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: (theme) => theme.spacing(3),
              py: (theme) => theme.spacing(2),
            }}
          >
            <Typography variant="h3" sx={{}}>
              Other Details
            </Typography>
            <CustomButton
              className="buttonDense"
              onClick={() => handleAddNewOtherDetail(true)}
              color={'buttonPrimary'}
            >
              Add Other Details
            </CustomButton>
          </Box>
          <Divder spacing={1} />
          <MatTable
            showCheckbox={false}
            columns={columnForOtherDetail}
            data={formik.values.otherDetails}
            action={actionBodyForOtherDetails}
            total={0}
            handlePageChange={(newPage) => {
              getListing(
                {
                  page: String(newPage),
                },
                setPage,
              );
            }}
            pagination={false}
          />
        </CustomBox>
        <Divder spacing={1} />
        <CustomBox padding={0}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: (theme) => theme.spacing(3),
              py: (theme) => theme.spacing(2),
            }}
          >
            <Typography variant="h3" sx={{}}>
              Return Policy
            </Typography>
            <CustomButton
              className="buttonDense"
              onClick={() => handleAddNewReturnPolicy(true)}
              color={'buttonPrimary'}
            >
              Add Return Policy
            </CustomButton>
          </Box>
          <Divder spacing={1} />
          <MatTable
            showCheckbox={false}
            columns={columnForReturnPolicy}
            data={formik.values.returnPolicy}
            action={actionBodyForReturnPolicy}
            total={0}
            handlePageChange={(newPage) => {
              getListing(
                {
                  page: String(newPage),
                },
                setPage,
              );
            }}
            pagination={false}
          />
        </CustomBox>
        <CustomButton
          sx={{ marginTop: 2 }}
          type="submit"
          color={'buttonPrimary'}
        >
          Submit
        </CustomButton>
      </form>
    </div>
  );
}

export default CmsDetailPage;
