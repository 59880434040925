import React, { useState } from 'react';
import CustomButton from '../../Components/CommonComp/Buttons/CustomButton';
import MainLayout from '../Layouts/MainLayout';
import Coupon from './Coupon';
import { useNavigate } from 'react-router-dom';

function CouponContainer() {
  const [navParams, setNavParams] = useState({
    navTitle: 'All Coupons Data',
    navSubTitle: 'Welcome to Cotton Slice',
  });
  const navigate = useNavigate();
  const handleNavParams = (obj) => {
    setNavParams({
      ...navParams,
      ...obj,
    });
  };
  return (
    <MainLayout
      navTitle={navParams.navTitle}
      navSubTitle={navParams.navSubTitle}
      navBarRightComp={
        <CustomButton
          sx={{ padding: '12px 15px', ml: (theme) => theme.spacing(2) }}
          color={'buttonPrimary'}
          onClick={() => navigate('/coupon/addCoupon')}
        >
          Add Coupon
        </CustomButton>
      }
    >
      <Coupon handleNavParams={handleNavParams} />
    </MainLayout>
  );
}

export default CouponContainer;
