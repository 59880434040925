const BASE_URL_MAIN = `${process.env.REACT_APP_API_URL}`;
const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_IMAGE_URL = process.env.REACT_APP_BASE_IMAGE_URL;
const S3_BASE_URL = process.env.REACT_APP_S3_URL;
const LIVE_WEBSITE_URL = process.env.REACT_APP_LIVE_WEBSITE_URL;
const REACT_APP_SALT = process.env.REACT_APP_SALT;
const PRODUCT_URL = process.env.REACT_APP_PRODUCT_URL;
const MAIN_SRM_BACKEND = 'https://backend.srm.pixeldot.in/api/';

let ApiConfig = {
  token: null,
  BASE_URL,
  BASE_IMAGE_URL,
  S3_BASE_URL,
  REACT_APP_SALT,
  LIVE_WEBSITE_URL,
  PRODUCT_URL,
  //Admin Apis
  login: `${BASE_URL_MAIN}admin/auth/admin-login`,

  //Admin Users Apis
  adminUsersList: `${BASE_URL_MAIN}admin/users/list`,
  adminCreateUser: `${BASE_URL_MAIN}admin/users/create-user`,
  adminUpdateUser: `${BASE_URL_MAIN}admin/users/update-user`,
  adminDeleteUser: `${BASE_URL_MAIN}admin/users/delete-user`,

  //Admin Order Apis
  adminUserOrderCreate: `${BASE_URL_MAIN}admin/order/create`,
  adminUserOrderUpdate: `${BASE_URL_MAIN}admin/order/update`,
  adminNonUserOrderCreate: `${BASE_URL_MAIN}admin/order/create-order-for-new-user`,
  getAllOrderListing: `${BASE_URL_MAIN}admin/order/list`,
  getAllPickupListing: `${BASE_URL_MAIN}admin/order/pickup`,
  updateOrderStatus: `${BASE_URL_MAIN}admin/order/update-status`,
  //Admin Shiprocket Order Apis
  generateAwbOrders: `${BASE_URL_MAIN}admin/order/awb-generate`,
  cancelShiprocketOrder: `${BASE_URL_MAIN}admin/order/cancel-order`,
  cancelShiprocketShipment: `${BASE_URL_MAIN}admin/order/cancel-shipment`,
  generateShiprocketPickup: `${BASE_URL_MAIN}admin/order/pickup`,

  //Admin Products Apis
  adminCreateProduct: `${BASE_URL_MAIN}admin/product/create`,
  adminUpdateProduct: `${BASE_URL_MAIN}admin/product/update`,
  adminDeleteProduct: `${BASE_URL_MAIN}admin/product/delete`,

  //Admin Category Apis
  adminCreateCategory: `${BASE_URL_MAIN}admin/category/create`,
  adminUpdateCategory: `${BASE_URL_MAIN}admin/category/update`,
  adminDeleteCategory: `${BASE_URL_MAIN}admin/category/delete`,

  //Admin App Setting Apis
  adminCreateAppSetting: `${BASE_URL_MAIN}admin/cms/create`,
  adminUpdateAppSetting: `${BASE_URL_MAIN}admin/cms/update`,
  adminDeleteAppSetting: `${BASE_URL_MAIN}admin/cms/delete`,

  //Admin Coupon Apis
  adminCreateCoupon: `${BASE_URL_MAIN}admin/coupon/create`,
  adminUpdateCoupon: `${BASE_URL_MAIN}admin/coupon/update`,
  adminDeleteCoupon: `${BASE_URL_MAIN}admin/coupon/delete`,
  couponsList: `${BASE_URL_MAIN}admin/coupon/get`,

  //Common Coupon Apis

  //Common Apis
  //Common Address Apis
  userAddressList: `${BASE_URL_MAIN}common/address/list`,
  addAddress: `${BASE_URL_MAIN}common/address/add`,
  editAddress: `${BASE_URL_MAIN}common/address/edit`,
  deleteAddress: `${BASE_URL_MAIN}common/address/delete`,
  getAddressByPincode: `${BASE_URL_MAIN}common/address/pin-code`,

  //Common Category Apis
  categoryList: `${BASE_URL_MAIN}common/category/get`,

  //Common Product Apis
  productList: `${BASE_URL_MAIN}common/product/get`,

  //Common Wishlist Apis
  wishlist: `${BASE_URL_MAIN}common/wishlist/get-wishlist-product`,
  addRemoveWishlist: `${BASE_URL_MAIN}common/wishlist`,

  //Common User Apis
  userCartList: `${BASE_URL_MAIN}common/cart/get-cart`,
  addToCart: `${BASE_URL_MAIN}common/cart/add-to-cart`,
  editToCart: `${BASE_URL_MAIN}common/cart/update-cart`,
  removeFromCart: `${BASE_URL_MAIN}common/cart/remove-from-cart`,

  //Common App Setting Apis
  getAppSetting: `${BASE_URL_MAIN}common/cms/get`,

  //Admin Users Apis
  stoneListing: `${BASE_URL_MAIN}common/cms/get`,

  //Departments List Apis
  departmentsListing: `${MAIN_SRM_BACKEND}departments`,

  //Employees List Apis
  employeesListing: `${MAIN_SRM_BACKEND}employees`,

  //Stones List Apis
  stonesListing: `${MAIN_SRM_BACKEND}stones`,

  //Kapan List Apis
  kapanListing: `${MAIN_SRM_BACKEND}kapan`,

  //Kapan List Apis
  galexyPlanningListing: `${MAIN_SRM_BACKEND}galaxyPlanning`,
  getGelexyDataFromKapanId: `${MAIN_SRM_BACKEND}galaxyPlanning/getDataFromKapanId`,
};

export default ApiConfig;
